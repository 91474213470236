import React, { useState, useEffect, useRef } from "react";
import ViewRateModal from '../../Modal/ViewRateModal'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { adminMainUrl, mainUrl } from '../../../Config';
import Api from '../../../Api';
import { useFormik } from 'formik';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import Cookies from "js-cookie";
import { RateRequestSchema } from '../../../ValidationScema';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { getViewRate } from '../../../Redux/action/MasterAction';
import Beatloader from '../../../Common/Loaders/Beatloader';

const ViewRate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const { b64id } = useParams();
  const { status_id } = useParams();
  const [startDate, setStartDate] = useState(null);
  const [rateValidity, setRateValidity] = useState(null);
  const [rate, setRate] = useState(null);
  const [edit, setEdit] = useState(false);
  const [airlineId, setAirlineId] = useState();
  const [index, setIndex] = useState(0);

  const urlString = window.location.href;
  const url = new URL(urlString);
  const path = url.pathname.slice(1);
  const searchParams = url.search;
  const q = path + searchParams;

  //setting data 
  const [ship_quotation_no, setShip_quotation_no] = useState("");
  const [ship_date_of_creation, setShip_date_of_creation] = useState("");
  const [ship_buy_ref_no, setShip_buy_ref_no] = useState("");
  const [ship_rate_validity, setShip_rate_validity] = useState("");
  const [ship_validity_against, setShip_validity_against] = useState("");
  const [ship_quotation_rise_in, setShip_quotation_rise_in] = useState("");

  const [com_company_name, setCom_company_name] = useState("");
  const [com_address, setCom_address] = useState("");
  const [com_phone_no, setCom_phone_no] = useState("");
  const [com_email, setCom_email] = useState("");
  const [com_gstin, setCom_gstin] = useState("");
  const [com_country_id, setCom_country_id] = useState("");
  const [com_state_id, setCom_state_id] = useState("");

  const [gen_customer_name, setGen_customer_name] = useState("");
  const [gen_address, setGen_address] = useState("");
  const [gen_billing_account, setGen_billing_account] = useState("");
  const [gen_attention_to, setGen_attention_to] = useState("");
  const [gen_airline, setGen_airline] = useState("");
  const [gen_place_of_receipt, setGen_place_of_receipt] = useState("");
  const [gen_commodity, setGen_commodity] = useState("");
  const [gen_airport_of_landing, setGen_airport_of_landing] = useState("");
  const [gen_cargo_weight, setGen_cargo_weight] = useState("");
  const [gen_airport_of_discharge, setGen_airport_of_discharge] = useState("");
  const [gen_incoterms, setGen_incoterms] = useState("");
  const [gen_tentative_transit_time, setGen_tentative_transit_time] = useState("");
  const [gen_find_place_of_delivery, setGen_find_place_of_delivery] = useState("");
  const [gen_notes, setGen_notes] = useState("");
  const [quote_tarrif, setQuote_tarrif] = useState([]);
  const [formatted_quote_tarrif, setFormatted_quote_tarrif] = useState([]);
  const [Id, setId] = useState(null)
  useEffect(() => {
    if (!token) {
      toast.error("Please login to continue.");
      navigate(`/admin-login?burl=${encodeURIComponent(q)}`);
    } else {
      if (b64id) {
        let id = atob(b64id);
        dispatch(getViewRate(token, id));
      } else {
        toast.error("Please enter correct url.")
        navigate(-1);
      }
    }
  }, []);

  let { loadingViewRate, viewRateData, viewRateDataError } = useSelector(state => state?.MasterReducer);
  const downloadQuote = async (id) => {
    try {
      // Fetch the file from the API
      const response = await fetch(`${adminMainUrl}/api/pricing/download-quote?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf', // Specify that the response is a PDF
        },
      });
  
      // Check if the response is OK (status 200)
      if (!response.ok) {
        throw new Error(`Failed to download quote with id ${id}.`);
      }
  
      // Convert the response to a blob
      const blob = await response.blob();
  
      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(new Blob([blob]));
  
      // Create a link and trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `quotation_${id}.pdf`); // Set the file name with the id
      document.body.appendChild(link);
      link.click();
  
      // Clean up the temporary link after the download
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };
  useEffect(() => {
    // console.log("viewRateData", viewRateData);
    // console.log("index",index)
    if (viewRateData) {
      if (Array.isArray(viewRateData) && viewRateData.length) {
        setShip_quotation_no(viewRateData[index]?.ship_quotation_no)
        setShip_date_of_creation(viewRateData[index]?.ship_date_of_creation)
        setShip_buy_ref_no(viewRateData[index]?.ship_buy_ref_no);
        setShip_validity_against(viewRateData[index]?.ship_validity_against);
        setShip_rate_validity(viewRateData[index]?.ship_rate_validity);
        setShip_quotation_rise_in(viewRateData[index]?.ship_quotation_rise_in);

        setCom_company_name(viewRateData[index]?.com_company_name)
        setCom_address(viewRateData[index]?.com_address)
        setCom_phone_no(viewRateData[index]?.com_phone_no)
        setCom_email(viewRateData[index]?.com_email)
        setCom_gstin(viewRateData[index]?.com_gstin)
        setCom_country_id(viewRateData[index]?.country_data?.name)
        setCom_state_id(viewRateData[index]?.state_data?.name)

        setGen_customer_name(viewRateData[index]?.gen_customer_name)
        setGen_address(viewRateData[index]?.gen_address)
        setGen_billing_account(viewRateData[index]?.gen_billing_account)
        setGen_attention_to(viewRateData[index]?.gen_attention_to)
        setGen_airline(viewRateData[index]?.gen_airline)
        setGen_place_of_receipt(viewRateData[index]?.gen_place_of_receipt)
        setGen_commodity(viewRateData[index]?.gen_commodity)
        setGen_airport_of_landing(viewRateData[index]?.gen_airport_of_landing)
        setGen_cargo_weight(viewRateData[index]?.gen_cargo_weight)
        setGen_airport_of_discharge(viewRateData[index]?.gen_airport_of_discharge)
        setGen_incoterms(viewRateData[index]?.gen_incoterms)
        setGen_tentative_transit_time(viewRateData[index]?.gen_tentative_transit_time)
        setGen_find_place_of_delivery(viewRateData[index]?.gen_find_place_of_delivery)
        setGen_notes(viewRateData[index]?.gen_notes);

        setQuote_tarrif(viewRateData[index]?.quote_tarrif);
        setId(viewRateData[index]?.id)

      }
    }
  }, [loadingViewRate, viewRateData, viewRateDataError, index]);


  const handleIndex = (ind) => {
    // console.log(ind);
    setIndex(ind);
  }

  function formatDate(isoString) {
    if (isoString) {
      const date = new Date(isoString);  // Convert the ISO string to a Date object

      const options = { day: '2-digit', month: 'short', year: 'numeric' };
      const formatter = new Intl.DateTimeFormat('en-GB', options);

      return formatter.format(date).replace(/\s/g, '-');  // Replace spaces with hyphens
    } else {
      return "";
    }

  }

  useEffect(() => {
    if (quote_tarrif) {
      const groupedData = Object.values(
        quote_tarrif.reduce((acc, obj) => {
          // Check if the airline_code group exists, if not, create an empty array
          if (!acc[obj.airline_code]) {
            acc[obj.airline_code] = [];
          }
          // Push the object into the appropriate group
          acc[obj.airline_code].push(obj);
          return acc;
        }, {})
      );

      setFormatted_quote_tarrif(groupedData);
    }
  }, [quote_tarrif])


  const formik = useFormik({
    initialValues: {
      uhqpa_id: status_id,
      shipment_info: {
        id: '',
        quotation_number: '',
        date_of_creation: '',
        buy_ref_number: '',
        rate_validity: '',
        validity_against: '',
        quotation_rise_in: '',
      },
      company_info: {
        id: '',
        company_name: '',
        address: '',
        phone_number: '',
        email: '',
        gstin: '',
        country: '',
        state: '',
      },
      general_info: {
        id: '',
        company_name: '',
        address: '',
        billing_account: '',
        attention_to: '',
        airline: '',
        place_of_receipt: '',
        commodity: '',
        airport_of_loading: '',
        cargo_weight: '',
        airport_of_discharge: '',
        incoterms: '',
        tentative_transit_time: '',
        final_delivery_place: '',
        notes: '',
      },
      tarrif_details: [
        {
          airline_name: '',
          charge_head: '',
          buying_price: '',
          selling_price: '',
          narration: '',
          valid_upto: null,
          currency: '',
          exchange_rate: '',
          unit: '',
          fc_amount: '',
          rate: '',
        },
      ],

    },
    validationSchema: RateRequestSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const token = Cookies.get("token");

        const response = await fetch(`${mainUrl}/api/v.1/updateQuotationRate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(values),
        });
        if (response.ok) {
          const responseData = await response.json();

          setEdit(false)
          // const updatedInformation = responseData?.uhqpa_id
          // await createQuotationStatus(updatedInformation);
          // navigate('/admin-dashboard/view-updated-query-list');
          resetForm()
          toast.success(responseData?.message);
        } else {
          toast.error("Wrong Credentials");
        }
      } catch (error) {
        toast.error(error);
      }
    },
  });


  const transformedArray = rate?.data?.tariffDetails
    ? Object.entries(rate?.data?.tariffDetails).map(([key, value]) => ({
      [key]: value
    }))
    : [];


  useEffect(() => {
    if (rate?.data?.tariffDetails) {
      const tariffDetails = transformedArray.map((value) => ({
        id: value?.id,
        airline_name: value.airline_name || '',
        charge_head: value.charge_head || '',
        buying_price: value.buying_price || '',
        selling_price: value.selling_price || '',
        narration: value.narration || '',
        valid_upto: value.valid_upto || '',
        currency: value.currency || '',
        exchange_rate: value.exchange_rate || '',
        unit: value.unit || '',
        fc_amount: value.fc_amount || '',
        rate: value.rate || '',
      }));

      formik.setFieldValue('tarrif_details', tariffDetails);
    }

    if (rate?.data?.shipmentInfo) {
      const shipmentInfo = rate.data.shipmentInfo[0];
      formik.setFieldValue('shipment_info', {
        id: shipmentInfo?.id || '',
        quotation_number: shipmentInfo?.quotation_number || '',
        date_of_creation: startDate || '',
        buy_ref_number: shipmentInfo?.buy_ref_number || '',
        rate_validity: rateValidity,
        validity_against: shipmentInfo?.validity_against || '',
        quotation_rise_in: shipmentInfo?.quotation_rise_in || '',
      });
      if (shipmentInfo?.date_of_creation) {
        const formattedDate = format(new Date(shipmentInfo.date_of_creation), 'yyyy-MM-dd');
        setStartDate(new Date(formattedDate));
        formik.setFieldValue('shipment_info.date_of_creation', formattedDate);
      }
      if (shipmentInfo?.rate_validity) {
        const formattedDate = format(new Date(shipmentInfo.rate_validity), 'yyyy-MM-dd');
        setRateValidity(new Date(formattedDate));
        formik.setFieldValue('shipment_info.rate_validity', formattedDate);
      }
    }

    if (rate?.data?.companyInfo) {
      const companyInfo = rate.data.companyInfo[0];
      formik.setFieldValue('company_info', {
        id: companyInfo?.id || '',
        company_name: companyInfo?.company_name || '',
        address: companyInfo?.address || '',
        phone_number: companyInfo?.phone_number || '',
        email: companyInfo?.email || '',
        gstin: companyInfo?.gstin || '',
        country: companyInfo?.country || '',
        state: companyInfo?.state || '',
      });
    }

    if (rate?.data?.generalInfo) {
      const generalInfo = rate.data.generalInfo[0];
      formik.setFieldValue('general_info', {
        id: generalInfo?.id || '',
        company_name: generalInfo?.company_name || '',
        address: generalInfo?.address || '',
        billing_account: generalInfo?.billing_account || '',
        attention_to: generalInfo?.attention_to || '',
        airline: generalInfo?.airline || '',
        place_of_receipt: generalInfo?.place_of_receipt || '',
        commodity: generalInfo?.commodity || '',
        airport_of_loading: generalInfo?.airport_of_loading || '',
        cargo_weight: generalInfo?.cargo_weight || '',
        airport_of_discharge: generalInfo?.airport_of_discharge || '',
        incoterms: generalInfo?.incoterms || '',
        tentative_transit_time: generalInfo?.tentative_transit_time || '',
        final_delivery_place: generalInfo?.final_delivery_place || '',
        notes: generalInfo?.notes || '',
      });
    }
  }, [rate]);




  // State to manage the visibility of the quotation list
  const [showList, setShowList] = useState(false);

  // Function to handle button click to show/hide the list
  const toggleList = () => {
    setShowList(!showList);
  };



  if (loadingViewRate) {
    return <Beatloader />
  }

  // const tableRef = useRef(null);
  // const [isGrabbing, setIsGrabbing] = useState(false);
  // const [startX, setStartX] = useState(0);
  // const [scrollLeft, setScrollLeft] = useState(0);

  // const handleMouseDown = (e) => {
  //   const slider = tableRef.current;
  //   setIsGrabbing(true);
  //   slider.classList.add('grabbing'); // Apply grabbing CSS class
  //   setStartX(e.pageX - slider.offsetLeft);
  //   setScrollLeft(slider.scrollLeft);
  // };

  // const handleMouseUp = () => {
  //   const slider = tableRef.current;
  //   setIsGrabbing(false);
  //   slider.classList.remove('grabbing'); // Remove grabbing CSS class
  // };

  // const handleMouseMove = (e) => {
  //   if (!isGrabbing) return;
  //   e.preventDefault();
  //   const slider = tableRef.current;
  //   const x = e.pageX - slider.offsetLeft;
  //   const walk = (x - startX) * 2; // Speed of scroll
  //   slider.scrollLeft = scrollLeft - walk;
  // };


  return (
    <>
      <div className="container">
        <div className="col-md-12">
          <div className="main_section_detail">
            <div className="main-nav-det d-sm-flex justify-content-between align-items-center">
              <h2>
                <Link to={-1} className="query_info">
                  <img src="/img/re_darect.png" alt="Query" />
                </Link>
                Quotation Air Export Form
              </h2>

            </div>
            <div className="main_detail_form Air_Export_Form_bg">
              <form onSubmit={formik.handleSubmit} className="Air_Export_bg_main">
                <div className="main-nav-det">
                  <h4>
                    <span>
                      <img src="/img/gen.png" alt="gen" />
                    </span>
                    Shipment Information
                  </h4>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="input_box_section ">
                      <label>Quotation Number </label>
                      <input
                        disabled={edit === false ? true : false}
                        type="text"
                        className="form-control"
                        value={ship_quotation_no}
                        // value={formik.values.shipment_info.quotation_number}
                        onChange={(e) => {
                          const num = e.target.value.replace(/[^0-9]/g, '');
                          formik.setFieldValue('shipment_info.quotation_number', num);
                        }}
                        onBlur={formik.handleBlur}
                        name="shipment_info.quotation_number"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input_box_section por_main_detail">
                      <label>Date of Creation </label>
                      <div className="custom-datepicker">
                        <DatePicker
                          disabled={true}
                          selected={ship_date_of_creation}
                          className="form-control"
                        />
                      </div>
                      <span>
                        <img src="/img/simple-calende.png" alt="Calendar" />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input_box_section ">
                      <label>Buying Reference Number</label>
                      <input
                        disabled={true}
                        type="text"
                        className="form-control"
                        value={ship_buy_ref_no}
                        // value={formik.values.shipment_info.buy_ref_number}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="shipment_info.buy_ref_number"

                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input_box_section por_main_detail">
                      <label>Rate Validity <span>*</span> </label>

                      <div className="custom-datepicker">
                        <DatePicker
                          disabled={true}
                          // selected={rateValidity}
                          selected={ship_rate_validity}
                          className="form-control"
                        />
                      </div>

                      <span>
                        <img src="/img/simple-calende.png" alt="Calendar" />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input_box_section ">
                      <label>Validity Against</label>
                      <input
                        disabled={true}
                        type="text"
                        className="form-control"
                        value={ship_validity_against}
                        // value={formik.values.shipment_info.validity_against}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="shipment_info.validity_against"

                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input_box_section ">
                      <label>Quotation Rise in <span>*</span></label>
                      <input
                        disabled={true}
                        type="text"
                        className="form-control"
                        value={ship_quotation_rise_in}
                        // value={formik.values.shipment_info.quotation_rise_in}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="shipment_info.quotation_rise_in"

                      />
                    </div>
                  </div>
                </div>
                <div className="main-nav-det">
                  <h4>
                    <span>
                      <img src="/img/Product.png" alt="gen" />
                    </span>
                    Company Information
                  </h4>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Company Name <span>*</span> </label>
                      <input
                        disabled={true}
                        type="text"
                        className="form-control"
                        value={com_company_name}
                        // value={formik.values.company_info.company_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.company_name"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Address <span>*</span> </label>
                      <input
                        disabled={true}
                        type="text"
                        value={com_address}
                        className="form-control"
                        // value={formik.values.company_info.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.address"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Phone Number<span>*</span> </label>
                      <input
                        disabled={true}
                        type="text"
                        className="form-control"
                        value={com_phone_no}
                        // value={formik.values.company_info.phone_number}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.phone_number"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Email ID <span>*</span> </label>
                      <input
                        disabled={true}
                        type="text"
                        className="form-control"
                        value={com_email}
                        // value={formik.values.company_info.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.email"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>GSTIN <span>*</span> </label>
                      <input
                        disabled={true}
                        type="text"
                        className="form-control"
                        value={com_gstin}
                        // value={formik.values.company_info.gstin}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.gstin"

                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input_box_section">
                      <label>Country <span>*</span> </label>
                      <input
                        disabled={true}
                        type="text"
                        className="form-control"
                        value={com_country_id}
                        // value={formik.values.company_info.country}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.country"

                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input_box_section">
                      <label>State <span>*</span> </label>
                      <input
                        disabled={true}
                        type="text"
                        className="form-control"
                        value={com_state_id}
                        // value={formik.values.company_info.state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.state"

                      />
                    </div>
                  </div>
                </div>
                <div className="main-nav-det">
                  <h4>
                    <span>
                      <img src="/img/Product.png" alt="gen" />
                    </span>
                    General Information
                  </h4>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Customer Name <span>*</span> </label>
                      <input
                        disabled={true}
                        type="text"
                        className="form-control"
                        value={gen_customer_name}
                        // value={formik.values.general_info.company_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.company_name"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Address </label>
                      <input
                        disabled={true}
                        type="text"
                        className="form-control"
                        value={gen_address}
                        // value={formik.values.general_info.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.address"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Billing Account</label>
                      <input
                        disabled={true}
                        type="text"
                        className="form-control"
                        value={gen_billing_account}
                        // value={formik.values.general_info.billing_account}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.billing_account"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Attention To</label>
                      <input
                        disabled={true}
                        type="text"
                        className="form-control"
                        value={gen_attention_to}
                        // value={formik.values.general_info.attention_to}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.attention_to"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Airline </label>
                      <input
                        disabled={true}
                        type="text"
                        className="form-control"
                        value={gen_airline}
                        // value={formik.values.general_info.airline}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.airline"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Place of Receipt </label>
                      <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        value={gen_place_of_receipt}
                        // value={formik.values.general_info.place_of_receipt}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.place_of_receipt"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Commodity </label>
                      <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        value={gen_commodity}
                        // value={formik.values.general_info.commodity}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.commodity"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Air Port of Loading <span>*</span> </label>
                      <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        value={gen_airport_of_landing}
                        // value={formik.values.general_info.airport_of_loading}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.airport_of_loading"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Cargo Weight (Kg) </label>
                      <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        value={gen_cargo_weight}
                        // value={formik.values.general_info.cargo_weight}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.cargo_weight"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Air Port of Discharge <span>*</span> </label>
                      <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        value={gen_airport_of_discharge}
                        // value={formik.values.general_info.airport_of_discharge}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.airport_of_discharge"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Incoterms</label>
                      <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        value={gen_incoterms}
                        // value={formik.values.general_info.incoterms}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.incoterms"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Tentative Transit Time <span>*</span></label>
                      <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        value={gen_tentative_transit_time}
                        // value={formik.values.general_info.tentative_transit_time}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.tentative_transit_time"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Final Place of Delivery <span>*</span></label>
                      <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        value={gen_find_place_of_delivery}
                        // value={formik.values.general_info.final_delivery_place}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.final_delivery_place"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Notes</label>
                      <textarea
                        disabled={true}
                        rows={3}
                        className="form-control"
                        placeholder="Examples..."
                        defaultValue={""}
                        value={gen_notes}
                        // value={formik.values.general_info.notes}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.notes"

                      />
                    </div>
                  </div>
                </div>
                {/* <button className='search-btn text-white py-1' type="submit">Submit</button> */}
              </form>
              <div className="main-nav-det">
                <h4>
                  <span>
                    <img src="/img/Product.png" alt="gen" />
                  </span>
                  Tariff Details
                </h4>
              </div>
              {formatted_quote_tarrif && formatted_quote_tarrif.length > 0 ?
                formatted_quote_tarrif.map((value, i) => {
                  return <div className="users-table border-redi" key={i + 1}>
                    <div className="table_detail_tariff table-wrapper" >
                      <table key={value[0]?.airline_code} className="posts-table geeks table-responsive">
                        <thead>
                          <tr className="users-table-admin-th">
                            {/*<th>
                               <div className="main-box-det-check">
                                <label className="users-table__checkbox" id="new_text">
                                  <input type="checkbox" className="check" />
                                  {value[0]?.airline_code}
                                </label>
                              </div> 
                            </th>*/}
                            <th>Airline code</th>
                            <th>Charge Head</th>
                            <th>Buying Price</th>
                            <th>Selling Price</th>
                            <th>Narration</th>
                            <th>Valid Upto</th>
                            <th>Currency</th>
                            <th>Exchange Rate</th>
                            <th>Unit</th>
                            <th>FC amount</th>
                            <th>Rate</th>
                            <th>Cargo Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {value.map((val, index) => (
                            <tr className="main_table_row_new" key={index + 1}>
                              <td>{val.airline_code}</td>
                              <td>{val.charge_head}</td>
                              <td>{val.buying_price}</td>
                              <td>{val.selling_price}</td>
                              <td>{val.narration}</td>
                              <td>{formatDate(val.valid_upto)}</td>
                              <td>{val.currency}</td>
                              <td>{val.exchange_rate}</td>
                              <td>{val.unit_info?.name}</td>
                              <td>{val.fc_amount}</td>
                              <td>{val.rate}</td>
                              <td>{val?.cargo_type_info?.name}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                })
                :
                <div>
                  <div className="text-center"><i>No tarrif data found.</i></div>
                </div>
              }
              {/* {rate?.data?.tariffDetails && (typeof rate?.data?.tariffDetails === 'object' && !Array.isArray(rate?.data?.tariffDetails)) &&
                Object.entries(rate?.data?.tariffDetails).map(([airlineName, details]) => (
                  <div className="flex_detail_main ">
                    <div className="table_detail_tariff table-responsive">

                      <table key={airlineName} className="posts-table geeks table-responsive">
                        <thead>
                          <tr className="users-table-admin-th">
                            <th>
                              <div className="main-box-det-check">
                                <label className="users-table__checkbox" id="new_text">
                                  <input type="checkbox" className="check" />
                                  {airlineName}
                                </label>
                              </div>
                            </th>
                            <th>Charge Head</th>
                            <th>Narration</th>
                            <th>Currency</th>
                            <th>Unit</th>
                            <th>Rate</th>
                            <th>FC Amount</th>
                            <th>Exchange Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {details.map((value, index) => (
                            <tr className="main_table_row_new" key={`${airlineName}-${index}`}>
                              <td>{value.airline_name}</td>
                              <td>{value.charge_head}</td>
                              <td>{value.narration}</td>
                              <td>{value.currency}</td>
                              <td>{value.unit}</td>
                              <td>{value.rate}</td>
                              <td>{value.fc_amount}</td>
                              <td>{value.exchange_rate}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                    </div>
                    <div className="box_button_main">
                      <a href="#" data-bs-toggle="modal" data-bs-target="#myModal" onClick={()=>{
                       setAirlineId(details)
                      }}>
                        <img src="/img/edit-line.png" alt="edit" />
                      </a>
                    </div>
                  </div>
                ))
              } */}

              <div className="main-nav-det-button text-right main-Export-button">
                <button onClick={() => { downloadQuote(Id) }} className='search-btn text-white py-1'>Download Quotation</button>
              </div>



            </div>
          </div>
        </div>

        <div className="quotation-container">
          <button className="toggle-button search-btn text-white py-1" onClick={toggleList}>
            {showList ? "Hide Quotation Rate History" : "Show Quotation Rate History"}
          </button>

          {showList && (
            <div className="quotation-list">
              <form>
                {viewRateData?.map((rate, ind) => {
                  const groupedByAirlineCode = rate.quote_tarrif.reduce((acc, curr) => {
                    if (!acc[curr.airline_code]) {
                      acc[curr.airline_code] = [];
                    }
                    acc[curr.airline_code].push(curr);
                    return acc;
                  }, {});
                  const quote_tarrif_formatted = Object.values(groupedByAirlineCode);
                  // console.log("quote tarrif formatted", quote_tarrif_formatted, index, ind + 1, index === ind);
                  return <div className="radio-item-1 d-flex align-items-center" key={ind + 1}>
                    <div style={{ width: "20px", height: "20px" }}>

                      <input
                        type="radio"
                        name="tariffHistory"
                        className='tariff-history-radio me-0'
                        // checked={index == ind + 1}
                        checked={index === ind}
                        onClick={() => handleIndex(ind)}
                      />
                    </div>
                    <div className="row tariff-history">
                      <div className="row mb-3">
                        <div className="col-md-3 history-label-details"><b>Quotation Number: </b>{rate?.ship_quotation_no}</div>
                        <div className="col-md-3 history-label-details"><b>Date of Creation: </b>{formatDate(rate?.ship_date_of_creation)}</div>
                        <div className="col-md-3 history-label-details"><b>Reference Number: </b>{rate?.ship_buy_ref_no}</div>
                        <div className="col-md-3 history-label-details"><b>Rate Validity: </b>{formatDate(rate?.ship_rate_validity)}</div>
                      </div>
                      {quote_tarrif_formatted?.map((v, _i) => {
                        return <div className="users-table border-redi" key={_i + 1}>
                          <div className="table_detail_tariff table-wrapper">
                            <table className="posts-table ">
                              <thead>
                                <tr className="users-table-tariff n_detail_comp">
                                  <th>Airline No.</th>
                                  <th>Charge Head</th>
                                  <th>Buyer Amount</th>
                                  <th>Seller Amount</th>
                                  <th>Narration</th>
                                  <th>Valid Up To</th>
                                  <th>Currency</th>
                                  <th>Exchange Rate</th>
                                  <th>Unit</th>
                                  <th>FC Amount</th>
                                  <th>Rate</th>
                                  <th>Cargo Type</th>
                                </tr>
                              </thead>
                              <tbody>
                                {v.map((tarrif, _in) => {
                                  return <tr className="main_table_row_new n_detail_comp" key={_in + 1}>
                                    <td>{tarrif.airline_code}</td>
                                    <td>{tarrif.charge_head}</td>
                                    <td>{tarrif.buying_price}</td>
                                    <td>{tarrif.selling_price}</td>
                                    <td>{tarrif.narration}</td>
                                    <td>{formatDate(tarrif.valid_upto)}</td>
                                    <td>{tarrif.currency}</td>
                                    <td>{tarrif.exchange_rate}</td>
                                    <td>{tarrif.unit_info?.name}</td>
                                    <td>{tarrif.fc_amount}</td>
                                    <td>{tarrif.rate}</td>
                                    <td>{tarrif?.cargo_type_info?.name}</td>
                                  </tr>
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      })}
                    </div>
                  </div>
                })}

              </form>
              {/* <p className="selected-quote">
            <strong>Selected Quotation: </strong>{selectedQuote}
          </p> */}
            </div>
          )}
        </div>

      </div>
      <ViewRateModal airlineId={airlineId} modal={"myModal"} tariffDetails={transformedArray} />
    </>
  )
}

export default ViewRate