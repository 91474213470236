import React, { useState, useEffect } from "react";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import CancelIcon from "@mui/icons-material/Cancel";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { FocusError } from 'focus-formik-error';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BeatLoader } from "react-spinners";
import Cookies from "js-cookie";

import {
  validationCreateServiceSchema,
} from "../../../ValidationScema";
import { admin, adminMainUrl } from "../../../Config";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";

const QuoteRequestForm = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [productType, setProductTypes] = useState([]);
  const [cargoType, setCargoType] = useState([]);
  const [shipmentMode, setShipmentMode] = useState([]);
  const [shipmentType, setShipmentType] = useState([]);
  const [shipmentVia, setShipmentVia] = useState([]);
  const [packageType, setPackageType] = useState([]);
  const [selectedImg, setSelectedImg] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [weightUnit, setWeightUnit] = useState([]);
  const [RequiredServiceId, setRequiredServiceId] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isLastRowFilled = () => {
    const lastRow =
      formik.values.package_info[formik.values.package_info?.length - 1];
    return Object.values(lastRow).every((value) => value !== "");
  };
  const handleRemoveRow = (index) => {
    const updatedPackageInfo = formik.values.package_info.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("package_info", updatedPackageInfo);
  };

  const handleAddRow = () => {
    if (isLastRowFilled()) {
      formik.setFieldValue("package_info", [
        ...formik.values.package_info,
        {
          package_type_id: "",
          length: "",
          width: "",
          height: "",
          max_weight: "",
          max_weight_unit_id: "",
          gross_weight: "",
          gross_weight_unit_id: "",
          total_boxes: ""
        },
      ]);
    } else {
      Swal.fire(
        "Error",
        "Please fill in all fields before adding a new row.",
        "error"
      );
    }
  };
  const GetProductType = async () => {
    try {
      const data = await Api(`${adminMainUrl}/api/product-type/get`, "GET");

      // Filter to include only items where status is true
      const options = data?.data
        ?.filter((item) => item.status === true)
        .map((item) => ({
          productId: item.id,
          value: item.name,
          label: item.name,
        }));

      setProductTypes(options);
    } catch (error) {
      setProductTypes([]);
      console.error("Error fetching data:", error);
    }
  };

  const GetWeightUnit = async () => {
    try {
      const data = await Api(`${adminMainUrl}/api/weight-unit/get`, "GET");
      const options = data?.data
        ?.filter((item) => item.status === true).map((item) => ({
          WeightUnitId: item.id,
          value: item.name,
          label: item.name,
        }));
      setWeightUnit(options);
    } catch (error) {
      setWeightUnit([]);

      console.error("Error fetching data:", error);
    }
  };
  
  const RequiredService = async () => {
    try {
      const data = await Api(`${adminMainUrl}/api/required-service/get`, "GET");
      const options = data?.data
        ?.filter((item) => item.status === true).map((item) => ({
          RequiredServiceId: item.id,
          value: item.name,
          label: item.name,
        }));
      setRequiredServiceId(options);
    } catch (error) {
      setRequiredServiceId([]);

      console.error("Error fetching data:", error);
    }
  };
  const GetCargoType = async () => {
    try {
      const data = await Api(`${adminMainUrl}/api/cargo-type/get`, "GET");
      const options = data?.data
        ?.filter((item) => item.status === true).map((item) => ({
          cargoId: item.id,
          value: item.name,
          label: item.name,
        }));
      setCargoType(options);
    } catch (error) {
      setCargoType([]);

      console.error("Error fetching data:", error);
    }
  };
  const GetShipmentMode = async () => {
    try {
      const data = await Api(`${adminMainUrl}/api/shipment-mode/get`, "GET");
      const options = data?.data
        ?.filter((item) => item.status === true).map((item) => ({
          shipmentModeId: item.id,
          value: item.name,
          label: item.name,
        }));
      setShipmentMode(options);
    } catch (error) {
      setShipmentMode([]);

      console.error("Error fetching data:", error);
    }
  };
  const GetShipmentType = async () => {
    try {
      const data = await Api(`${adminMainUrl}/api/shipment-type/get`, "GET");
      const options = data?.data
        ?.filter((item) => item.status === true).map((item) => ({
          shipmentTypeId: item.id,
          value: item.name,
          label: item.name,
        }));
      setShipmentType(options);
    } catch (error) {
      setShipmentType([]);

      console.error("Error fetching data:", error);
    }
  };
  const GetShipmentVia = async () => {
    try {
      const data = await Api(`${adminMainUrl}/api/shipment-via/get`, "GET");
      const options = data?.data
        ?.filter((item) => item.status === true).map((item) => ({
          shipmentViaId: item.id,
          value: item.name,
          label: item.name,
        }));
      setShipmentVia(options);
    } catch (error) {
      setShipmentVia([]);

      console.error("Error fetching data:", error);
    }
  };

  const GetPackageType = async () => {
    try {
      const data = await Api(`${adminMainUrl}/api/package-type/get`, "GET");
      const options = data?.data
        ?.filter((item) => item.status === true).map((item) => ({
          packageTypeId: item.id,
          value: item.name,
          label: item.name,
        }));
      setPackageType(options);
    } catch (error) {
      setPackageType([]);

      console.error("Error fetching data:", error);
    }
  };

  const cancelImage = (index) => {
    const newImages = formik.values.files.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("files", newImages);
    setSelectedImg(newImages);
  };

  const cancelVideo = (index) => {
    const newVideos = formik.values.videos.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("videos", newVideos);
    setSelectedVideo(newVideos);
  };



  const formik = useFormik({
    initialValues: {
      full_name: "",
      phone: "",
      email: "",
      origin: "",
      destination: "",
      product_name: "",
      product_type_id: "",
      shipment_type_id: "",
      shipment_mode_id: "",
      shipment_via_id: "",
      cargo_type_id: "",
      required_servie_id: "",
      expected_transit_date: "",
      detailed_requirement: "",
      request_via: "panel",
      agreement: 0,
      // agreement:"",
      package_info: [
        {
          package_type_id: "",
          length: "",
          width: "",
          height: "",
          max_weight: "",
          max_weight_unit_id: "",
          gross_weight: "",
          gross_weight_unit_id: "",
          total_boxes: ""
        }
      ],
      files: [],
      videos: []
    },
    validationSchema: validationCreateServiceSchema,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setIsSubmitting(true);
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key !== 'files' && key !== 'videos' && key !== 'package_info') {
          formData.append(key, values[key]);
        }
      });
      values.files.forEach((image) => {
        formData.append('files', image);
      });
      values.videos.forEach((video) => {
        formData.append('videos', video);
      });
      if (Array.isArray(values.package_info)) {
        formData.append('package_info', JSON.stringify(values.package_info));
      } else {
        console.error("package_info is not an array");
      }
      try {
        const response = await axios.post(`${adminMainUrl}/api/quotation/create`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'auth-token': Cookies.get("token")
          },
        });
        resetForm();
        setSelectedImg([]);
        setSelectedVideo([])
        toast.success(response.data.msg);
        navigate('/admin-dashboard/query-list')
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
      finally {
        setIsSubmitting(false); // Stop the spinner
      }
    },
  });

  useEffect(() => {
    GetProductType();
    GetCargoType();
    GetShipmentMode();
    GetShipmentType();
    GetShipmentVia();
    GetPackageType();
    RequiredService();
    GetWeightUnit();
  }, []);

  return (
    <>
      <div className="container   ">
        <div className="row justify-content-center">

          <div className="col-md-12 px-3">
            <div className="main_section_detail">
              <div className="main-nav-det">
                <h2>Fill the Quote Request Form</h2>
              </div>
              {
                isSubmitting ?
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "500px", width: "100%" }}
                  >
                    <BeatLoader />
                  </div> :
                  <form onSubmit={formik.handleSubmit}>
                    <FocusError formik={formik} />
                    <div className="main_detail_form">
                      <div className="main-nav-det">
                        <h4>General information</h4>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="input_box_section ">
                            <label>
                              Full Name <span>*</span>
                            </label>
                            <input
                              type="text"
                              name="full_name"
                              className="form-control"
                              placeholder="Enter Full Name "
                              value={formik?.values?.full_name}
                              onChange={formik?.handleChange}
                              onBlur={formik?.handleBlur}
                              pattern="[a-zA-Z\s]*"
                              onKeyPress={(event) => {
                                const inputValue = event.target.value;
                                // Prevent space as the first character
                                if (inputValue.length === 0 && event.key === ' ') {
                                  event.preventDefault();
                                }
                                // Allow only alphabets and spaces
                                if (!/[a-zA-Z\s]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik?.touched.full_name && formik?.errors.full_name ? (
                              <div className="error">{formik?.errors.full_name}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input_box_section ">
                            <label>
                              Phone Number <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Phone Number"
                              name="phone"
                              value={formik?.values?.phone}
                              onChange={formik?.handleChange}
                              onBlur={formik?.handleBlur}
                              // pattern="\d*"
                              maxLength={10}
                              onKeyPress={(event) => {
                                const inputValue = event.target.value;
                                // Prevent space as the first character
                                if (inputValue.length === 0 && event.key === ' ') {
                                  event.preventDefault();
                                }
                                if (!/\d/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik?.touched?.phone && formik?.errors?.phone ? (
                              <div className="error">{formik?.errors?.phone}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input_box_section ">
                            <label>
                              Email Id <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Email"
                              name="email"
                              value={formik?.values.email}
                              onChange={formik?.handleChange}
                              onBlur={formik?.handleBlur}
                              onKeyPress={(event) => {
                                const inputValue = event.target.value;
                                // Prevent space as the first character
                                if (inputValue.length === 0 && event.key === ' ') {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik?.touched.email && formik?.errors?.email ? (
                              <div className="error">{formik?.errors?.email}</div>
                            ) : null}
                          </div>
                        </div>

                      </div>
                      <div className="main-nav-det">
                        <h4>Pickup Information</h4>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="input_box_section ">
                            <label>
                              Origin <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Origin "
                              name="origin"
                              value={formik?.values?.origin}
                              onChange={formik?.handleChange}
                              onBlur={formik?.handleBlur}
                              onKeyPress={(event) => {
                                const inputValue = event.target.value;
                                // Prevent space as the first character
                                if (inputValue.length === 0 && event.key === ' ') {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik?.touched?.origin && formik?.errors?.origin ? (
                              <div className="error">{formik?.errors?.origin}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input_box_section ">
                            <label>
                              Destination <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Destination"
                              name="destination"
                              value={formik?.values?.destination}
                              onChange={formik?.handleChange}
                              onBlur={formik?.handleBlur}
                              onKeyPress={(event) => {
                                const inputValue = event.target.value;
                                // Prevent space as the first character
                                if (inputValue.length === 0 && event.key === ' ') {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik.touched.destination &&
                              formik.errors.destination ? (
                              <div className="error">{formik.errors.destination}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input_box_section">
                            <label>
                              Product Name <span>*</span>
                            </label>
                            <input
                              type="text"
                              name="product_name"
                              className="form-control"
                              placeholder="Enter Product Name"
                              value={formik?.values?.product_name}
                              onChange={formik?.handleChange}
                              onBlur={formik?.handleBlur}
                              onKeyPress={(event) => {
                                const inputValue = event.target.value;
                                // Prevent space as the first character
                                if (inputValue.length === 0 && event.key === ' ') {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik.touched.product_name &&
                              formik.errors.product_name ? (
                              <div className="error">
                                {formik.errors.product_name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input_box_section">
                            <label>Product Type</label>
                            <div className="mt-2">
                              <Select
                                options={productType}
                                onChange={(selectedOptions) => {
                                  formik.setFieldValue(
                                    "product_type_id",
                                    selectedOptions.productId
                                  );
                                }}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {formik.touched.product_type_id &&
                              formik.errors.product_type_id ? (
                              <div className="error">
                                {formik.errors.product_type_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input_box_section">
                            <label>
                              Shipment Type <span>*</span>
                            </label>
                            <div className="mt-2">
                              <Select
                                options={shipmentType}
                                onChange={(selectedOption) =>
                                  formik.setFieldValue(
                                    "shipment_type_id",
                                    selectedOption.shipmentTypeId
                                  )
                                }
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {formik.touched.shipment_type_id &&
                              formik.errors.shipment_type_id ? (
                              <div className="error">
                                {formik.errors.shipment_type_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input_box_section">
                            <label>
                              Shipment Mode <span>*</span>
                            </label>
                            <div className="mt-2">
                              <Select
                                options={shipmentMode}
                                onChange={(selectedOption) =>
                                  formik.setFieldValue(
                                    "shipment_mode_id",
                                    selectedOption.shipmentModeId
                                  )
                                }
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {formik.touched.shipment_mode_id &&
                              formik.errors.shipment_mode_id ? (
                              <div className="error">
                                {formik.errors.shipment_mode_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input_box_section">
                            <label>
                              Shipment Via <span>*</span>
                            </label>
                            <div className="mt-2">
                              <Select
                                options={shipmentVia}
                                onChange={(selectedOption) =>
                                  formik.setFieldValue(
                                    "shipment_via_id",
                                    selectedOption.shipmentViaId
                                  )
                                }
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {formik.touched.shipment_via_id &&
                              formik.errors.shipment_via_id ? (
                              <div className="error">
                                {formik.errors.shipment_via_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input_box_section">
                            <label>
                              Cargo Type <span>*</span>
                            </label>
                            <div className="mt-2">
                              <Select
                                options={cargoType}
                                onChange={(selectedOption) =>
                                  formik.setFieldValue(
                                    "cargo_type_id",
                                    selectedOption.cargoId
                                  )
                                }
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {formik.touched.cargo_type_id &&
                              formik.errors.cargo_type_id ? (
                              <div className="error">
                                {formik.errors.cargo_type_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input_box_section">
                            <label>
                              Required Service <span>*</span>
                            </label>
                            <div className="mt-2">
                              <Select
                                options={RequiredServiceId}
                                onChange={(selectedOption) =>
                                  formik.setFieldValue(
                                    "required_servie_id",
                                    selectedOption.RequiredServiceId
                                  )
                                }
                                onBlur={formik.handleBlur}

                              />
                              {formik.touched.required_servie_id &&
                                formik.errors.required_servie_id ? (
                                <div className="error">
                                  {formik.errors.required_servie_id}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input_box_section">
                            <label>
                              Expected Transit Date <span>*</span>
                            </label>
                            <div
                              className="custom-datepicker"
                              style={{ position: "relative" }}
                            >
                              <DatePicker
                                placeholderText="Select a date"
                                minDate={new Date()}
                                selected={startDate}
                                onChange={(date) => {
                                  if (date < new Date().setHours(0, 0, 0, 0)) {
                                    Swal.fire(
                                      "Error",
                                      "Expected Date can not in past date",
                                      "error"
                                    );
                                    setStartDate(null);
                                    formik.setFieldValue("expected_transit_date", "");
                                  } else {
                                    setStartDate(date);
                                    formik.setFieldValue(
                                      "expected_transit_date",
                                      date
                                    );
                                  }
                                }}
                                onBlur={formik.handleBlur}
                              />
                              <img
                                src="/img/Calendar.png"
                                alt=""
                                style={{
                                  position: "absolute",
                                  top: "20px",
                                  right: "12px",
                                }}
                              />
                            </div>
                            {formik.touched.expected_transit_date &&
                              formik.errors.expected_transit_date ? (
                              <div className="error">
                                {formik.errors.expected_transit_date}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {/* <div className="col-md-4">
                    <div className="input_box_section">
                      <label>
                        Request via <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="request_via"
                        className="form-control"
                        placeholder="Enter Request via"
                        value={formik?.values?.request_via}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      {formik.touched.request_via &&
                        formik.errors.request_via ? (
                        <div className="error">
                          {formik.errors.request_via}
                        </div>
                      ) : null}
                    </div>
                  </div> */}
                        <div className="col-md-12">
                          <div className="input_box_section">
                            <label>
                              Detailed Requirement <span>*</span>
                            </label>
                            <textarea
                              name="detailed_requirement"
                              className="form-control"
                              placeholder="Enter Detailed Requirement"
                              value={formik?.values?.detailed_requirement}
                              onChange={formik?.handleChange}
                              onBlur={formik?.handleBlur}
                              rows={5}
                              onKeyPress={(event) => {
                                const inputValue = event.target.value;
                                // Prevent space as the first character
                                if (inputValue.length === 0 && event.key === ' ') {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik.touched.detailed_requirement &&
                              formik.errors.detailed_requirement ? (
                              <div className="error">
                                {formik.errors.detailed_requirement}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="main-nav-det">
                        <h4>Package Dimensions/Weight</h4>
                      </div>

                      <div>

                        {formik.values.package_info.map((packageItem, index) => (
                          <>
                            <div key={index} className={`row ${index > 0 ? 'border-top' : ''}`}  >
                              <div className="col-md-12 d-md-flex justify-content-between align-items-start">
                                <div className="input_box_section form-group">
                                  <div className="input-section">
                                    <label htmlFor="quantity">Quantity *</label>
                                    <div className="quantity-input">
                                      <input
                                        pattern="\d*"
                                        onKeyPress={(event) => {
                                          if (!/\d/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                        min={1}
                                        type="number"
                                        //    className="form-control"
                                        placeholder="Enter Quantity"
                                        value={packageItem.total_boxes}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        name={`package_info.${index}.total_boxes`}
                                        maxLength={8}
                                      />
                                      {/* <span className="check-icon">✔</span> */}
                                    </div>
                                    {formik.touched.package_info?.[index]?.total_boxes &&
                                      formik.errors.package_info?.[index]?.total_boxes ? (
                                      <div className="error">
                                        {formik.errors.package_info[index].total_boxes}
                                      </div>
                                    ) : null}
                                  </div>

                                  {/* Packaging Options */}
                                  <div className="input-section">
                                    <label>Packaging *</label>
                                    <div className="packaging-options mt-2">
                                      {packageType.map((option, pkgIndex) => (
                                        <button
                                          key={pkgIndex}
                                          className={`package-btn ${formik.values.package_info?.[index]?.package_type_id === option.value ? 'active' : ''}`}
                                          onClick={() => {
                                            // Set the selected package type in Formik's package_info array
                                            formik.setFieldValue(`package_info.${index}.package_type_id`, option.value);
                                            // setSelectedPackage(option.label); // Optionally store the selected package in local state if needed
                                          }}
                                        >
                                          {option.label}
                                          {formik.values.package_info?.[index]?.package_type_id === option.value && (
                                            <span className="check-icon">✔</span>
                                          )}
                                        </button>
                                      ))}

                                    </div>
                                    {formik.touched.package_info?.[index]?.package_type_id &&
                                      formik.errors.package_info?.[index]?.package_type_id ? (
                                      <div className="error">
                                        {formik.errors.package_info[index].package_type_id}
                                      </div>
                                    ) : null}
                                  </div>
                                  {/* <label>
                                                                Type of PKG <span>*</span>
                                                            </label>
                                                            <div className='mt-2'>
                                                                <Select
                                                                    options={packageType}
                                                                    value={packageType.find(option => option.value === formik.values.package_info?.[index]?.package_type_id) || null}
                                                                    onChange={(selectedOption) =>
                                                                        formik.setFieldValue(
                                                                            `package_info.${index}.package_type_id`,
                                                                            selectedOption.value
                                                                        )
                                                                    }
                                                                    onBlur={formik.handleBlur}
                                                                />
                                                            </div>
                                                            {formik.touched.package_info?.[index]?.package_type_id &&
                                                                formik.errors.package_info?.[index]?.package_type_id ? (
                                                                <div className="error">
                                                                    {formik.errors.package_info[index].package_type_id}
                                                                </div>
                                                            ) : null} */}
                                </div>
                                <div className='d-flex justify-content-center align-items-center gap-3'>
                                  <button
                                    type="button"
                                    className='bg-none add-package input_box_section'
                                    onClick={handleAddRow}
                                  >
                                    <span className='me-2'>
                                      <img src="/landingPageImg/pla.png" alt="img" />
                                    </span>
                                    Add another package
                                  </button>

                                  {formik.values.package_info.length > 1 && (
                                    <button
                                      className="remoove btn  w-fit input_box_section"
                                      onClick={() => handleRemoveRow(index)}
                                    >
                                      <i class="fa-solid fa-trash"></i>
                                    </button>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div class="form-group-1 ">
                                  <label for="dimensions">Dimensions (L x W x H per box) <span class="required">*</span></label>
                                  <div class="dimensions-input-container mt-2">
                                    <div class="input-wrapper">
                                      <input type="number" id="length" placeholder="Length"
                                        value={packageItem.length}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        name={`package_info.${index}.length`}
                                        pattern="\d*"
                                        min={1}
                                        maxLength={4}
                                        onKeyPress={(event) => {
                                          if (!/\d/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }} class="dimension-input" />

                                    </div>
                                    <div class="input-wrapper">
                                      <input type="number" id="width" placeholder="Width"
                                        value={packageItem.width}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        name={`package_info.${index}.width`}
                                        pattern="\d*"
                                        min={1}
                                        maxLength={4}
                                        onKeyPress={(event) => {
                                          if (!/\d/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }} class="dimension-input" />

                                    </div>
                                    <div class="input-wrapper">
                                      <input type="number" id="height" placeholder="Height"
                                        value={packageItem.height}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        name={`package_info.${index}.height`}
                                        pattern="\d*"
                                        maxLength={4}
                                        min={1}
                                        onKeyPress={(event) => {
                                          if (!/\d/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }} class="dimension-input" />

                                    </div>
                                    <select class="unit-select">
                                      {/* <option value="" disabled>unit</option> */}
                                      <option value="cm">Cm</option>
                                      <option value="inches">Inches</option>
                                    </select>
                                  </div>
                                  {formik.touched.package_info?.[index]?.length &&
                                    formik.errors.package_info?.[index]?.length ? (
                                    <div className="error">{formik.errors.package_info[index].length}</div>
                                  ) : null}
                                  {formik.touched.package_info?.[index]?.width &&
                                    formik.errors.package_info?.[index]?.width &&
                                    !formik.errors.package_info?.[index]?.length ? (
                                    <div className="error">{formik.errors.package_info[index].width}</div>
                                  ) : null}
                                  {formik.touched.package_info?.[index]?.height &&
                                    formik.errors.package_info?.[index]?.height &&
                                    !formik.errors.package_info?.[index]?.length &&
                                    !formik.errors.package_info?.[index]?.width ? (
                                    <div className="error">{formik.errors.package_info[index].height}</div>
                                  ) : null}

                                </div>

                              </div>
                              {/* <div className="col-md-4">
                                                        <div className="alfa_listing">
                                                            <div className="input_box_section">
                                                                <label>
                                                                    Dimensions (L x W x H per box) <span>*</span>
                                                                </label>

                                                                <ul className='mb-0 ps-0 mt-2'>
                                                                    <li>
                                                                        <div className="input_box_section mb-0">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="L"
                                                                                value={packageItem.length}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                name={`package_info.${index}.length`}
                                                                                pattern="\d*"
                                                                                maxLength={4}
                                                                                onKeyPress={(event) => {
                                                                                    if (!/\d/.test(event.key)) {
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </li>

                                                                    <li>
                                                                        <div className="input_box_section mb-0">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="X"
                                                                                readOnly=""
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="input_box_section mb-0">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="W"
                                                                                value={packageItem.width}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                name={`package_info.${index}.width`}
                                                                                pattern="\d*"
                                                                                maxLength={4}
                                                                                onKeyPress={(event) => {
                                                                                    if (!/\d/.test(event.key)) {
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </li>

                                                                    <li>
                                                                        <div className="input_box_section mb-0">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="X"
                                                                                readOnly=""
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="input_box_section mb-0">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="H"
                                                                                value={packageItem.height}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                name={`package_info.${index}.height`}
                                                                                pattern="\d*"
                                                                                maxLength={4}
                                                                                onKeyPress={(event) => {
                                                                                    if (!/\d/.test(event.key)) {
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </li>

                                                                </ul>
                                                                {formik.touched.package_info?.[index]?.length &&
                                                                    formik.errors.package_info?.[index]?.length ? (
                                                                    <div className="error">{formik.errors.package_info[index].length}</div>
                                                                ) : null}
                                                                {formik.touched.package_info?.[index]?.width &&
                                                                    formik.errors.package_info?.[index]?.width &&
                                                                    !formik.errors.package_info?.[index]?.length ? (
                                                                    <div className="error">{formik.errors.package_info[index].width}</div>
                                                                ) : null}
                                                                {formik.touched.package_info?.[index]?.height &&
                                                                    formik.errors.package_info?.[index]?.height &&
                                                                    !formik.errors.package_info?.[index]?.length &&
                                                                    !formik.errors.package_info?.[index]?.width ? (
                                                                    <div className="error">{formik.errors.package_info[index].height}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div> */}
                              <div className="col-md-3">
                                <div className="weight-unit-form">
                                  <label htmlFor="weight">
                                    Max Weight (per box) <span className="required">*</span>
                                  </label>
                                  <div class="dimensions-input-container mt-2">
                                    <div class="input-wrapper">
                                      <input type="number"
                                        min={1}
                                        placeholder="Max weight"
                                        value={packageItem.max_weight}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        name={`package_info.${index}.max_weight`}
                                        pattern="\d*"
                                        maxLength={8}
                                        onKeyPress={(event) => {
                                          if (!/\d/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }} class="dimension-input-1" />

                                    </div>


                                    <select
                                      id={`unit-select-${index}`}
                                      className="unit-select"

                                      value={formik.values.package_info?.[index]?.max_weight_unit_id || ''}
                                      onChange={(event) =>
                                        formik.setFieldValue(`package_info.${index}.max_weight_unit_id`, event.target.value)
                                      }
                                      onBlur={formik.handleBlur}
                                      name={`package_info.${index}.max_weight_unit_id`}
                                    >
                                      <option value="" disabled>Select unit</option>
                                      {weightUnit.map((unit) => (
                                        <option key={unit.WeightUnitId} value={unit.WeightUnitId}>
                                          {unit.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  {(formik.touched.package_info?.[index]?.max_weight_unit_id && formik.errors.package_info?.[index]?.max_weight_unit_id) ||
                                    (formik.touched.package_info?.[index]?.max_weight && formik.errors.package_info?.[index]?.max_weight) ? (
                                    <div className="error">
                                      {formik.errors.package_info?.[index]?.max_weight_unit_id || formik.errors.package_info?.[index]?.max_weight}
                                    </div>
                                  ) : null}

                                </div>
                              </div>
                              {/* <div className="col-md-4">
                                                        <div className="alfa_listing-kg">
                                                            <div className="input_box_section">
                                                                <label>
                                                                    Max Weight (per box) <span>*</span>
                                                                </label>

                                                                <ul className='mb-0 mt-2'>
                                                                    <li>
                                                                        <div className="input_box_section mb-0">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Max weight"
                                                                                value={packageItem.max_weight}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                name={`package_info.${index}.max_weight`}
                                                                                pattern="\d*"
                                                                                maxLength={8}
                                                                                onKeyPress={(event) => {
                                                                                    if (!/\d/.test(event.key)) {
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </li>

                                                                    <li className='w-100'>
                                                                        <div className="input_box_section w-swction_main mb-0 ">
                                                                            <Select
                                                                                options={weightUnit}
                                                                                value={weightUnit.find(option => option.value === formik.values.package_info?.[index]?.max_weight_unit_id) || null}
                                                                                onChange={(selectedOption) =>
                                                                                    formik.setFieldValue(
                                                                                        `package_info.${index}.max_weight_unit_id`,
                                                                                        selectedOption.value
                                                                                    )
                                                                                }
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                        </div>
                                                                    </li>

                                                                </ul>
                                                                {formik.touched.package_info?.[index]
                                                                    ?.max_weight &&
                                                                    formik.errors.package_info?.[index]?.max_weight ? (
                                                                    <div className="error">
                                                                        {formik.errors.package_info[index].max_weight}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div> */}
                              <div className="col-md-3">
                                <div className="weight-unit-form">
                                  <label htmlFor="weight">
                                    Total Gross Weight <span className="required">*</span>
                                  </label>
                                  <div class="dimensions-input-container mt-2">
                                    <div class="input-wrapper">
                                      <input pattern="\d*"
                                        onKeyPress={(event) => {
                                          if (!/\d/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                        type="number"
                                        min={1}
                                        placeholder="Gross Weight"
                                        value={packageItem.gross_weight}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        name={`package_info.${index}.gross_weight`}
                                        maxLength={8} className="dimension-input-1" />

                                    </div>
                                    <select
                                      id={`unit-select-${index}`}
                                      className="unit-select"
                                      value={formik.values.package_info?.[index]?.gross_weight_unit_id || ''}
                                      onChange={(event) =>
                                        formik.setFieldValue(
                                          `package_info.${index}.gross_weight_unit_id`,
                                          event.target.value
                                        )
                                      }
                                      onBlur={formik.handleBlur}
                                      name={`package_info.${index}.gross_weight_unit_id`}
                                    >
                                      <option value="" disabled>Select unit</option>
                                      {weightUnit.map((unit) => (
                                        <option key={unit.WeightUnitId} value={unit.WeightUnitId}>
                                          {unit.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>

                                  {(formik.touched.package_info?.[index]?.gross_weight_unit_id && formik.errors.package_info?.[index]?.gross_weight_unit_id) ||
                                    (formik.touched.package_info?.[index]?.gross_weight && formik.errors.package_info?.[index]?.gross_weight) ? (
                                    <div className="error">
                                      {formik.errors.package_info?.[index]?.gross_weight_unit_id || formik.errors.package_info?.[index]?.gross_weight}
                                    </div>
                                  ) : null}
                                  {/* {formik.touched.package_info?.[index]?.gross_weight_unit_id && formik.errors.package_info?.[index]?.gross_weight_unit_id ? (
                                                                <div className="error">
                                                                    {formik.errors.package_info[index].gross_weight_unit_id}
                                                                </div>
                                                            ) : null} */}
                                </div>
                              </div>
                              {/* <div className="col-md-4">
                                                        <div className="alfa_listing-kg">
                                                            <div className="input_box_section ">
                                                                <label>
                                                                    Total Gross Weight <span>*</span>
                                                                </label>

                                                                <ul className='mt-2 mb-0'>
                                                                    <li className="padd_left_inherit">
                                                                        <div className="input_box_section mb-0">
                                                                            <input
                                                                                pattern="\d*"
                                                                                onKeyPress={(event) => {
                                                                                    if (!/\d/.test(event.key)) {
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }}
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Total Gross Weight"
                                                                                value={packageItem.gross_weight}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                name={`package_info.${index}.gross_weight`}
                                                                                maxLength={8}
                                                                            />
                                                                        </div>
                                                                    </li>

                                                                    <li className='w-100'>
                                                                        <div className="input_box_section w-swction_main mb-0">
                                                                            <Select
                                                                                options={weightUnit}
                                                                                value={weightUnit.find(option => option.value === formik.values.package_info?.[index]?.gross_weight_unit_id) || null}
                                                                                onChange={(selectedOption) =>
                                                                                    formik.setFieldValue(
                                                                                        `package_info.${index}.gross_weight_unit_id`,
                                                                                        selectedOption.value
                                                                                    )
                                                                                }
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                        </div>
                                                                    </li>

                                                                </ul>
                                                                {formik.touched.package_info?.[index]
                                                                    ?.gross_weight &&
                                                                    formik.errors.package_info?.[index]
                                                                        ?.gross_weight ? (
                                                                    <div className="error">
                                                                        {
                                                                            formik.errors.package_info[index]
                                                                                .gross_weight
                                                                        }
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div> */}
                              {/* <div className="col-md-5">
                                                        <div className="input_box_section ">
                                                            <label>
                                                                Total no. of boxes <span>*</span>
                                                            </label>
                                                            <input
                                                                pattern="\d*"
                                                                onKeyPress={(event) => {
                                                                    if (!/\d/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Total number of boxes"
                                                                value={packageItem.total_boxes}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                name={`package_info.${index}.total_boxes`}
                                                                maxLength={8}
                                                            />
                                                            {formik.touched.package_info?.[index]?.total_boxes &&
                                                                formik.errors.package_info?.[index]?.total_boxes ? (
                                                                <div className="error">
                                                                    {formik.errors.package_info[index].total_boxes}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div> */}

                            </div>
                            {/* <div>
                                                    {formik.values.package_info.length > 1 && (
                                                        <button
                                                            className="remoove btn btn-danger w-fit my-3"
                                                            onClick={() => handleRemoveRow(index)}
                                                        >
                                                            Remove
                                                        </button>
                                                    )}
                                                </div> */}
                          </>
                        ))}
                        <div className="col-md-6">
                          <div className="main-nav-det ">
                            {/* <div className='d-md-flex justify-content-between align-items-center'>

                                                    <button
                                                        type="button"
                                                        className='bg-none add-package'
                                                        onClick={handleAddRow}
                                                    >
                                                        <span className='me-2'>
                                                            <img src="/landingPageImg/pla.png" alt="img" />
                                                        </span>
                                                        Add another package
                                                    </button>

                                                </div> */}

                            <div className="main_package_formar">
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                readOnly=""
                                placeholder="Download Sample File For Upload"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="main_section_detail">
                          <div className="related_to_main">
                            <h5>Related documents or Videos</h5>
                          </div>
                          <div className="related_to_main">
                            <h5>
                              Upload relevant documents and specifications to help the
                              forwarder
                            </h5>
                          </div>
                          <ul className="main_list_numvar">
                            <li>
                              <p>
                                Uploading cargo-related documents, such as the
                                proforma invoice and packing list, will help
                                forwarders return the most accurate quotes.
                              </p>
                            </li>
                            <li>
                              <p>
                                Accepted document formats: .jpg, .jpeg, .png, .pdf,
                                .docx, .doc, .xlsx, .xls. Accepted video formats:
                                .avi, .mp4, .wav.
                              </p>
                            </li>
                            <li>
                              <p>
                                The maximum file size accepted is 10MB per file, with
                                a limit of 3 files. For videos, the maximum size
                                accepted is 100MB, with a limit of 1 video.
                              </p>
                            </li>
                          </ul>
                          <div className="main_select_photo">
                            <ul>
                              <li>
                                <div className="file-input image-input-box">
                                  <input
                                    type="file"
                                    name="files"
                                    accept=".jpg, .jpeg, .png, .pdf, .docx, .doc, .xlsx, .xls"
                                    multiple
                                    id="files"
                                    onChange={(event) => {
                                      const selectedFiles = Array.from(event.target.files);
                                      const maxSize = 10 * 1024 * 1024; // 10 MB in bytes

                                      const validFiles = selectedFiles.filter(file => file.size <= maxSize);
                                      const invalidFiles = selectedFiles.filter(file => file.size > maxSize);

                                      if (invalidFiles.length > 0) {
                                        Swal.fire(
                                          "Error",
                                          "Each file must be less than 10 MB.",
                                          "error"
                                        );
                                      }

                                      const combinedFiles = [...formik.values.files, ...validFiles];

                                      if (combinedFiles.length > 3) {
                                        const limitedFiles = combinedFiles.slice(0, 3);
                                        formik.setFieldValue("files", limitedFiles);
                                        setSelectedImg(limitedFiles);
                                        Swal.fire(
                                          "Error",
                                          "You can only upload up to 3 files.",
                                          "error"
                                        );
                                      } else {
                                        formik.setFieldValue("files", combinedFiles);
                                        setSelectedImg(combinedFiles);
                                      }
                                    }}
                                    onBlur={formik.handleBlur}
                                  />
                                  <button
                                    type="button"
                                    className="folder_main_bro label_1"
                                  >
                                    <span>
                                      <img src="/img/upload.png" alt="file" />
                                    </span>{" "}
                                    Upload File
                                  </button>
                                  {formik.touched.files && formik.errors.files ? (
                                    <div>{formik.errors.files}</div>
                                  ) : null}
                                </div>
                                {formik.touched.files &&
                                  formik.errors.files ? (
                                  <div className="error">
                                    {formik.errors.files}
                                  </div>
                                ) : null}
                                {selectedImg &&
                                  selectedImg.map((img, index) => (
                                    <div
                                      key={index}
                                      className="mt-3 border border-[#cac8c8] rounded-md w-[110px] h-[110px] flex justify-center items-center relative"
                                    >
                                      <CancelIcon
                                        onClick={() => cancelImage(index)}
                                        style={{
                                          position: "absolute",
                                          top: "12px",
                                          right: "6px",
                                          cursor: "pointer",
                                        }}
                                      />
                                      <p className="my-3 pe-5 ps-2">{img.name}</p>
                                      {/* <img
                                  style={{ width: "100px", height: "100px" }}
                                  className="w-[100px] h-[100px] object-contain"
                                  src={URL.createObjectURL(img)}
                                  alt="Selected"
                                /> */}
                                    </div>
                                  ))}
                              </li>
                              <li>
                                <div className="file-input video-input-box">
                                  <input
                                    type="file"
                                    accept="video/*"
                                    name="videos"
                                    id="videos"
                                    onChange={(event) => {
                                      const files = Array.from(event.target.files);
                                      const maxSize = 100 * 1024 * 1024; // 100 MB in bytes

                                      if (files.length) {
                                        const newVideo = files[0];

                                        if (newVideo.size > maxSize) {
                                          Swal.fire(
                                            "Error",
                                            "The video file must be less than 100 MB.",
                                            "error"
                                          );
                                        } else {
                                          formik.setFieldValue("videos", [newVideo]);
                                          setSelectedVideo([newVideo]);
                                        }
                                      } else {
                                        console.log("No files selected");
                                      }
                                    }}
                                    onBlur={formik.handleBlur}
                                  />

                                  <button
                                    type="button"
                                    className="folder_main_bro label_1"
                                  >
                                    <span>
                                      <img src="/img/video.png" alt="video" />
                                    </span>{" "}
                                    Upload Videos
                                  </button>
                                  {formik.touched.videos && formik.errors.videos ? (
                                    <div>{formik.errors.videos}</div>
                                  ) : null}
                                </div>

                                {formik.touched.videos &&
                                  formik.errors.videos ? (
                                  <div className="error">
                                    {formik.errors.videos}
                                  </div>
                                ) : null}
                                {selectedVideo &&
                                  selectedVideo.map((video, index) => (
                                    <div
                                      key={index}
                                      className="border mt-3 border-[#cac8c8] rounded-md w-[110px] h-[110px] flex justify-center items-center relative"
                                    >
                                      <CancelIcon
                                        onClick={() => cancelVideo(index)}
                                        style={{
                                          position: "absolute",
                                          top: "12px",
                                          right: "6px",
                                          cursor: "pointer",
                                        }}
                                      />
                                      <p className="my-3 pe-5 ps-2">{video.name}</p>
                                      {/* <video
                                  style={{ width: "100px", height: "100px" }}
                                  className="w-[100px] h-[100px] object-contain"
                                  src={URL.createObjectURL(video)}
                                  controls
                                  alt="Selected"
                                /> */}
                                    </div>
                                  ))}
                              </li>
                            </ul>
                          </div>
                          <div className="main-box-det-check mt-3">
                            <label className="users-table__checkbox" id="new_text">
                              <input
                                type="checkbox"
                                className="check"
                                name="agreement"
                                checked={formik.values.agreement === 1}
                                onChange={(event) => {
                                  formik.setFieldValue("agreement", event.target.checked ? 1 : 0);
                                }}
                                onBlur={formik.handleBlur}
                              />
                              I have read, understood and agreed to abide by the{" "}
                              <b className="ms-2">Buying Request Posting Rules .</b>
                            </label>
                            {formik.touched.agreement && formik.errors.agreement ? (
                              <div className="error">{formik.errors.agreement}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4 mb-3">
                        <div className="col-md-12">
                          <button className="btn search-btn" type="submit" disabled={isSubmitting}>
                            {isSubmitting ? <div><i className="fa fa-spinner fa-spin" /> Submit</div> : "Submit"}
                          </button>

                        </div>
                      </div>
                    </div>
                  </form>

              }

            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default QuoteRequestForm;
