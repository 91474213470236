import React, { useState, useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Cookies from "js-cookie";
import { mainUrl } from '../../../Config';
import Swal from 'sweetalert2';
import Api from '../../../Api';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const QueryList = () => {
  const [loading, setLoading] = useState(true)
  const [selectedStatus, setSelectedStatus] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [status_id, setStatus_id] = useState('');
  const [full_name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [shipmentType, setShipmentType] = useState('');
  const [shipmentVia, setShipmentVia] = useState('');
  const [pickedBY, setPickedBy] = useState('');
  const [queryList, setQueryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const fromDate = startDate ? formatDate(startDate) : null;
  const toDate = endDate ? formatDate(endDate) : null;
  const navigate = useNavigate();
  function capitalizeText(text) {
    return text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const headers = [
    'REQUEST NO.',
    'Date',
    'NAME',
    'EMAIL',
    'PHONE NUMBER',
    'ORIGIN',
    'DESTINATION',
    'SHIPMENT TYPE',
    'SHIPMENT VIA',
    'REQUIRED SERVICE',
    'PICKED BY',
    'ACTIONS'
  ];

  const handleStatusClick = (statusName) => {
    setSelectedStatus(statusName);
  };

  const fetchQuotations = async (page, size) => {
    try {
      const token = Cookies.get("token");
      const requestBody = {
        page: currentPage,
        pageSize: 10,
        filterBy: {
          searchText,
          status_id,
          full_name,
          email,
          mobileNumber,
          origin,
          destination,
          shipmentType,
          shipmentVia,
          pickedBY,
          fromDate,
          toDate
        }
      };

      const response = await fetch(`${mainUrl}/api/v.1/getQuotationList`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        setLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setLoading(false);
      const data = await response.json();

      setQueryList(data)
      setPageSize(data?.data?.totalCount)
    } catch (error) {
      setLoading(false);
      alert(error)
    } finally {
      setLoading(false);
    }
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  useEffect(() => {
    fetchQuotations(currentPage, pageSize);
    createQuotationStatus();
  }, [currentPage, pageSize]);

  const filteredQuotations = selectedStatus
    ? queryList?.data?.quotations?.filter(quotation => quotation?.status_name === selectedStatus)
    : queryList?.data?.quotations;
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleStartDateChange = (date) => {
    if (date && date.getTime() === endDate?.getTime()) {
      alert("Start date and end date cannot be the same");
      return;
    }
    setStartDate(date);

    // Reset endDate if it is before the new startDate
    if (endDate && date && endDate.getTime() < date.getTime()) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (date) => {
    if (date && date.getTime() === startDate?.getTime()) {
      Swal.fire('Error', 'Start date and end date cannot be the same', 'error');
      return;
    }
    if (date && startDate && date.getTime() < startDate.getTime()) {
      Swal.fire('Error', 'End date cannot be before the start date', 'error');
      return;
    }
    setEndDate(date);
  };
  const [quotationId, setQuotationId] = useState()

  const viewInfo = (viewInfo) => {
    setQuotationId(viewInfo);
    createQuotationStatus();
    navigate(`/dashboard/query-information/${viewInfo}`)
  }
  const createQuotationStatus = async (information) => {
    try {
      const token = Cookies.get("token");
      const requesId = {
        quote_status_id: statusList[1].id,
        quote_ids: [quotationId]
      };
      const response = await fetch(`${mainUrl}/api/v.1/createQuotationStatus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requesId),
      });
    } catch (error) {
      console.log(error)
    }
  }
  const arr = new Array(5).fill(null);
  const [statusList, setStatusList] = useState();
  const [isResetting, setIsResetting] = useState(false);
  const GetUserInfo = async () => {
    try {
      const data = await Api(
        `${mainUrl}/api/v.1/statusList/${status_id}`,
        "GET"
      );
      if (data.status === 200) {
        const filteredData = data?.data?.filter(status =>
          status.status_name !== "Requested" &&
          status.status_name !== "process" &&
          status.status_name !== "In process" &&
          status.status_name !== "Sent" &&
          status.status_name !== "Sent for Price" &&
          status.status_name !== "In View"
        );
        setStatusList(filteredData)
      } else {
        console.error("API call was not successful:", data.error);
      }
    } catch (error) {

      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (isResetting) {
      fetchQuotations();
      setIsResetting(false);  // Reset the flag after fetching quotations
    }
    GetUserInfo();
  }, [isResetting]);
  const statusClassMap = {
    "Not Picked": "color_1",
    "In View": "color_2",
    "Price Received": "color_3",
    "Quotation Sent": "color_5",
    "Client Review": "color_6",
    "Converted": "color_7",
    "Not Converted": "color_8",
    "Picked": "color_4",
    "Price Viewed": "color_1",
  };
  // const Reset = () => {
  //   setSearchText('');
  //   setStatus_id('');
  //   setName('');
  //   setEmail('');
  //   setMobileNumber('');
  //   setOrigin('');
  //   setDestination('');
  //   setShipmentType('');
  //   setShipmentVia('');
  //   setPickedBy('');
  //   setStartDate('');
  //   setEndDate('');
  // };
  const resetFields = () => {
    setSearchText('');
    setStatus_id('');
    setName('');
    setEmail('');
    setMobileNumber('');
    setOrigin('');
    setDestination('');
    setShipmentType('');
    setShipmentVia('');
    setPickedBy('');
    setStartDate(null);
    setEndDate(null);
    setIsResetting(true);
  };
  return (
    <>
      <div className="container">
        <div className="col-md-12">
          <div className="main_section_detail">
            <div className="row">
              <div className="col-md-6">
                <div className="main-nav-det">
                  <h2>Query List</h2>
                </div>
              </div>
              <div className="col-md-6">
                <div className="button-det-list btn-style">
                  <Link to="/dashboard/quote-request-form">
                    <span>
                      <img src="/img/place.png" alt="" />
                    </span>{" "}
                    Manual Form
                  </Link>
                </div>
              </div>
            </div>
            {/* =============Search bar============= */}
            <div className="main_detail_filter">
              <form action="#">
                {/* <h3 class="stat-cards-info__title">Clear filters</h3> */}
                <div className="row align-items-center">
                  <div className=" col-lg-9">
                    <div className="search_main_filter">
                      <div className="row main_row">
                        <div className="col-md-4">
                          <div className="input_box_filet filter_icon">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                            />
                            <span>
                              <img src="/img/search.png" alt="" />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-2 ">
                          <div className="dropdown dropdown-filter-section">
                            <a
                              className="dropdown-toggle input_box_id_button"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span>
                                <img src="/img/bi_filter.png" alt="" />
                              </span>
                              Filter
                            </a>
                            <div
                              className="dropdown-menu main_drop_filter"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <table className="content-table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">REQUEST NO.</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Request No."
                                        value={status_id}
                                        onChange={(e) => setStatus_id(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  {/* <tr>
                                    <td>
                                      <label htmlFor="#">DATE </label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Date"
                                      />
                                    </td>
                                  </tr> */}
                                  <tr>
                                    <td>
                                      <label htmlFor="#">NAME</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Name"
                                        value={full_name}
                                        onChange={(e) => setName(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">EMAIL</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">PHONE NUMBER</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Number"
                                        value={mobileNumber}
                                        onChange={(e) => setMobileNumber(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">ORIGIN</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Origin"
                                        value={origin}
                                        onChange={(e) => setOrigin(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">DESTINATION</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Destination"
                                        value={destination}
                                        onChange={(e) => setDestination(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">SHIPMENT TYPE</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Shipment Type"
                                        value={shipmentType}
                                        onChange={(e) => setShipmentType(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">SHIPMENT VIA</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Shipment Via"
                                        value={shipmentVia}
                                        onChange={(e) => setShipmentVia(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">REQUIRED SERVICE</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Required Service"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">PICKED BY</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Picked By"
                                        value={pickedBY}
                                        onChange={(e) => setPickedBy(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="apply-btn cursor-pointer" onClick={() => { fetchQuotations() }}>

                                Apply
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div className="input_box_section por_main_detail-date">
                            <DatePicker
                              dateFormat="dd/MM/yy"
                              placeholderText="From"
                              selected={startDate}
                              onChange={handleStartDateChange}
                              isClearable
                            />
                            <span>
                              <img src="/img/date_icon.png" alt="Calendar" />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div className="input_box_section por_main_detail-date">
                            <DatePicker
                              dateFormat="dd/MM/yy"
                              placeholderText="To"
                              selected={endDate}
                              onChange={handleEndDateChange}
                              isClearable
                            />

                            <span>
                              <img src="/img/date_icon.png" alt="Calendar" />
                            </span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 d-flex align-items-center">
                    <div className='search-btn text-white btn-style w-fit' style={{ marginTop: "7px", marginBottom: "15px" }} onClick={() => { fetchQuotations() }}>Search</div>
                    <div
                      className='search-btn text-white w-fit'
                      style={{ marginTop: "7px", marginBottom: "15px" }}
                      onClick={() => { resetFields() }}
                    >
                      Clear
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* =============Search bar============= */}
            {/* <div className="row">
              <div className="col-md-12">
                <div className="main_listing_button">
                  <ul>
                    {statusList?.map((value, index) => (
                      <li
                        key={index}
                        style={{ cursor: "pointer" }}
                        className={statusClassMap[value?.status_name] || ""}
                        onClick={() => handleStatusClick(value?.status_name)}
                      >
                        {value?.status_name}
                      </li>
                    ))}

                  </ul>
                  <div className='text-end'>
                    <button className='search-btn text-white py-1' onClick={() => {
                      navigate('/dashboard/view-updated-query-list');
                      createQuotationStatus()
                    }}>
                      view list
                    </button>
                  </div>
                </div>
                <div className="scroll_listing_detail">
                  <div className="users-table ">
                    <table className="posts-table">
                      <thead>
                        <tr className="users-table-info">
                          <th>REQUEST NO.</th>
                          <th>DATE</th>
                          <th>NAME</th>
                          <th>EMAIL</th>
                          <th>PHONE NUMBER</th>
                          <th>ORIGIN</th>
                          <th>DESTINATION</th>
                          <th>SHIPMENT TYPE</th>
                          <th>SHIPMENT VIA</th>
                          <th>PICKED BY</th>
                          <th>VIEW INFORMATION</th>
                        </tr>
                      </thead>
                      <tbody>

                        {
                          loading ?
                            arr.map((value, index) => {
                              return (

                                <tr key={index}>
                                  <td
                                    colSpan="12"
                                    style={{
                                      height: "70px",
                                      padding: "0px 27px",
                                      width: "100%",
                                      textAlign: "center"
                                    }}
                                  >
                                    <Skeleton count={2} />
                                  </td>

                                </tr>
                              )
                            })
                            :
                            queryList?.data?.quotations?.length > 0 ?
                              queryList?.data?.quotations?.map((value, index) => {
                                return (
                                  <tr>
                                    <td className="color_1 text-center">{value?.quotation_id}</td>
                                    <td className="color_1">
                                      {formatDate(value?.createdAt)}
                                    </td>
                                    <td className="color_1">{value?.full_name}</td>
                                    <td className="color_1">{value?.email}</td>
                                    <td>{value?.mobile}</td>
                                    <td className="color_1">{value?.origin}</td>
                                    <td className="color_1">{value?.destination}</td>
                                    <td className="color_1">{value?.shipment_type}</td>
                                    <td className="color_1">{value?.shipment_via}</td>
                                    <td className="color_1">

                                      Vishal
                                    </td>
                                    <td>
                                      <div className="view_info_button" onClick={() => { viewInfo(value?.quotation_id) }} >
                                        View Information
                                      </div>
                                    </td>
                                  </tr>
                                )
                              }) :
                              <tr >
                                <td
                                  colSpan="12"
                                  style={{
                                    height: "300px",
                                    width: "100%",
                                    textAlign: "center"
                                  }}
                                >
                                  No data available
                                </td>

                              </tr>
                        }


                      </tbody>
                    </table>

                    <div className="pagination-controls my-5 d-flex justify-content-center align-items-center" >
                      <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                        <img src="/img/right.png" className='mx-2' alt="right" />
                      </button>
                      <span className='search-btn py-2 px-3 mx-3'> {currentPage}</span>
                      <button onClick={() => handlePageChange(currentPage + 1)} disabled={queryList?.data?.quotations?.length < 10}>
                        <img src="/img/left.png" className='mx-2' alt="right" />
                      </button>

                    </div>

                  </div>
                </div>
              </div>
            </div> */}

            <div className="container mt-4">

              <div className='text-end mb-2'>
                <button className='search-btn text-white py-1' onClick={() => {
                  navigate('/dashboard/view-updated-query-list');
                  createQuotationStatus()
                }}>
                  view list
                </button>
              </div>
              <ul className="nav nav-pills nav-slider mb-3 border-2" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold active position-relative" id="pills-rebid-tab" data-bs-toggle="pill" data-bs-target="#pills-rebid" type="button" role="tab" aria-controls="pills-rebid" aria-selected="true">All</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold position-relative" id="pills-send-process" data-bs-toggle="pill" data-bs-target="#pills-process" type="button" role="tab" aria-controls="pills-process" aria-selected="false">Not Picked</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold position-relative" id="pills-send-process" data-bs-toggle="pill" data-bs-target="#pills-process" type="button" role="tab" aria-controls="pills-process" aria-selected="false">Price Received</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold position-relative" id="pills-send-process" data-bs-toggle="pill" data-bs-target="#pills-process" type="button" role="tab" aria-controls="pills-process" aria-selected="false">Price Viewed</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold position-relative" id="pills-send-process" data-bs-toggle="pill" data-bs-target="#pills-process" type="button" role="tab" aria-controls="pills-process" aria-selected="false">Quotation Sent</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold position-relative" id="pills-send-process" data-bs-toggle="pill" data-bs-target="#pills-process" type="button" role="tab" aria-controls="pills-process" aria-selected="false">Client Review</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold position-relative" id="pills-send-process" data-bs-toggle="pill" data-bs-target="#pills-process" type="button" role="tab" aria-controls="pills-process" aria-selected="false">Converted</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold position-relative" id="pills-send-process" data-bs-toggle="pill" data-bs-target="#pills-process" type="button" role="tab" aria-controls="pills-process" aria-selected="false">Not Converted</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold position-relative" id="pills-send-process" data-bs-toggle="pill" data-bs-target="#pills-process" type="button" role="tab" aria-controls="pills-process" aria-selected="false">Picked</button>
                </li>
              </ul>

              <div className="tab-content rounded-3 text-danger" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-rebid" role="tabpanel" aria-labelledby="pills-rebid-tab">
                  <div className="scroll_listing_detail">
                    <div className="users-table ">
                      <table className="posts-table">
                        <thead>
                          <tr className="users-table-info">
                            {headers.map((header) => (
                              <th key={header}>{capitalizeText(header)}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>

                          {
                            loading ?
                              arr.map((value, index) => {
                                return (

                                  <tr key={index}>
                                    <td
                                      colSpan="12"
                                      style={{
                                        height: "70px",
                                        padding: "0px 27px",
                                        width: "100%",
                                        textAlign: "center"
                                      }}
                                    >
                                      <Skeleton count={2} />
                                    </td>

                                  </tr>
                                )
                              })
                              :
                              queryList?.data?.quotations?.length > 0 ?
                                queryList?.data?.quotations?.map((value, index) => {
                                  return (
                                    <tr>
                                      <td className="color_1 text-center">{value?.quotation_id}</td>
                                      <td className="color_1">
                                        {formatDate(value?.createdAt)}
                                      </td>
                                      <td className="color_1">{value?.full_name}</td>
                                      <td className="color_1">{value?.email}</td>
                                      <td>{value?.mobile}</td>
                                      <td className="color_1">{value?.origin}</td>
                                      <td className="color_1">{value?.destination}</td>
                                      <td className="color_1">{value?.shipment_type}</td>
                                      <td className="color_1">{value?.shipment_via}</td>
                                      <td className="color_1">Both</td>
                                      <td className="color_1">

                                        Vishal
                                      </td>
                                      <td>
                                        <div className="d-flex justify-content-center align-items-center" onClick={() => { viewInfo(value?.quotation_id) }} >
                                          <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                            <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                }) :
                                <tr >
                                  <td
                                    colSpan="12"
                                    style={{
                                      height: "300px",
                                      width: "100%",
                                      textAlign: "center"
                                    }}
                                  >
                                    No data available
                                  </td>

                                </tr>
                          }


                        </tbody>
                      </table>

                      {/* Pagination Controls */}
                      <div className="pagination-controls my-2 d-flex justify-content-center align-items-center" >
                        <button >
                          <img src="/img/right.png" className='mx-2' alt="right" />
                        </button>
                        <span className='search-btn py-2 px-3 mx-3'>1</span>
                        <button>
                          <img src="/img/left.png" className='mx-2' alt="right" />
                        </button>

                      </div>

                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-process" role="tabpanel" aria-labelledby="pills-process-tab">
                  <div className="scroll_listing_detail">
                    <div className="users-table ">
                      <table className="posts-table">
                        <thead>
                          <tr className="users-table-info">
                            {headers.map((header) => (
                              <th key={header}>{capitalizeText(header)}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr >
                            <td
                              colSpan="12"
                              style={{
                                height: "300px",
                                width: "100%",
                                textAlign: "center"
                              }}
                            >
                              No data available
                            </td>

                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default QueryList