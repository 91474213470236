
import axios from "axios";
import { adminMainUrl } from "../../Config";

import Cookies from 'js-cookie';
//PRODUCT//
export const ProductType = (slug) => {
    return async function (dispatch) {

        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token, // Use 'auth-token' header as required by your API
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        };


        await fetch(`${adminMainUrl}/api/product-type/get`, config)
            .then((res) => {

                res.json().then((resData) => {
                    dispatch(fetchProductTypeSuccess(resData));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchProductTypeRequest = () => ({
    type: "FIND_PRODUCT_PAGE_REQUEST",
});

export const fetchProductTypeSuccess = (data) => ({
    type: "FIND_PRODUCT_PAGE_SUCCESS",
    payload: data,
});

export const fetchProductTypeFailure = (error) => ({
    type: "FIND_PRODUCT_PAGE_FAILURE",
    payload: error,
});
//PRODUCT//

//Shipment//
export const ShipmentTypeApi = (slug) => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token, // Use 'auth-token' header as required by your API
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        };


        await fetch(`${adminMainUrl}/api/shipment-type/get`, config)
            .then((res) => {

                res.json().then((resData) => {
                    dispatch(fetchShipmentTypeSuccess(resData));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchShipmentTypeRequest = () => ({
    type: "FIND_SHIPMENT_PAGE_REQUEST",
});

export const fetchShipmentTypeSuccess = (data) => ({
    type: "FIND_SHIPMENT_PAGE_SUCCESS",
    payload: data,
});

export const fetchShipmentTypeFailure = (error) => ({
    type: "FIND_SHIPMENT_PAGE_FAILURE",
    payload: error,
});
//Shipment//

//ShipmentMode//
export const ShipmentModeTypeApi = (slug) => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token, // Use 'auth-token' header as required by your API
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        };


        await fetch(`${adminMainUrl}/api/shipment-mode/get`, config)
            .then((res) => {

                res.json().then((resData) => {
                    dispatch(fetchShipmentModeTypeSuccess(resData));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchShipmentModeTypeRequest = () => ({
    type: "FIND_SHIPMENTMODE_PAGE_REQUEST",
});

export const fetchShipmentModeTypeSuccess = (data) => ({
    type: "FIND_SHIPMENTMODE_PAGE_SUCCESS",
    payload: data,
});

export const fetchShipmentModeTypeFailure = (error) => ({
    type: "FIND_SHIPMENTMODE_PAGE_FAILURE",
    payload: error,
});
//ShipmentMode//

//ShipmentVia//
export const ShipmentViaTypeApi = (slug) => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token, // Use 'auth-token' header as required by your API
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        };


        await fetch(`${adminMainUrl}/api/shipment-via/get`, config)
            .then((res) => {

                res.json().then((resData) => {
                    dispatch(fetchShipmentViaTypeSuccess(resData));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchShipmentViaTypeRequest = () => ({
    type: "FIND_SHIPMENTVIA_PAGE_REQUEST",
});

export const fetchShipmentViaTypeSuccess = (data) => ({
    type: "FIND_SHIPMENTVIA_PAGE_SUCCESS",
    payload: data,
});

export const fetchShipmentViaTypeFailure = (error) => ({
    type: "FIND_SHIPMENTVIA_PAGE_FAILURE",
    payload: error,
});
//ShipmentVia//

//CargoType//
export const CargoTypeApi = (slug) => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        };


        await fetch(`${adminMainUrl}/api/cargo-type/get`, config)
            .then((res) => {

                res.json().then((resData) => {
                    dispatch(fetchCargoTypeSuccess(resData));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchCargoTypeRequest = () => ({
    type: "FIND_CARGO_TYPE_PAGE_REQUEST",
});

export const fetchCargoTypeSuccess = (data) => ({
    type: "FIND_CARGO_TYPE_PAGE_SUCCESS",
    payload: data,
});

export const fetchCargoTypeTypeFailure = (error) => ({
    type: "FIND_CARGO_TYPE_PAGE_FAILURE",
    payload: error,
});
//CargoType//

//DepartmentType//
export const DepartmentTypeApi = (slug) => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        };


        await fetch(`${adminMainUrl}/api/department/get`, config)
            .then((res) => {

                res.json().then((resData) => {
                    dispatch(fetchDepartmentTypeSuccess(resData));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchDepartmentTypeRequest = () => ({
    type: "FIND_DEPARTMENT_TYPE_PAGE_REQUEST",
});

export const fetchDepartmentTypeSuccess = (data) => ({
    type: "FIND_DEPARTMENT_TYPE_PAGE_SUCCESS",
    payload: data,
});

export const fetchDepartmentTypeTypeFailure = (error) => ({
    type: "FIND_DEPARTMENT_TYPE_PAGE_FAILURE",
    payload: error,
});
//DepartmentType//

//IdProof//
export const IdProofApi = (slug) => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        };


        await fetch(`${adminMainUrl}/api/id-proof-type/get`, config)
            .then((res) => {

                res.json().then((resData) => {
                    dispatch(fetchIdProofSuccess(resData));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchIdProofRequest = () => ({
    type: "FIND_ID_PROOF_TYPE_PAGE_REQUEST",
});

export const fetchIdProofSuccess = (data) => ({
    type: "FIND_ID_PROOF_TYPE_PAGE_SUCCESS",
    payload: data,
});

export const fetchIdProofTypeFailure = (error) => ({
    type: "FIND_ID_PROOF_TYPE_PAGE_FAILURE",
    payload: error,
});
//IdProof//

//Package//
export const PackageApi = (slug) => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        };


        await fetch(`${adminMainUrl}/api/package-type/get`, config)
            .then((res) => {

                res.json().then((resData) => {
                    dispatch(fetchPackageSuccess(resData));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchPackageRequest = () => ({
    type: "FIND_PACKAGE_TYPE_PAGE_REQUEST",
});

export const fetchPackageSuccess = (data) => ({
    type: "FIND_PACKAGE_TYPE_PAGE_SUCCESS",
    payload: data,
});

export const fetchPackageTypeFailure = (error) => ({
    type: "FIND_PACKAGE_TYPE_PAGE_FAILURE",
    payload: error,
});
//Package//

//Gender//
export const GenderApi = (slug) => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        };


        await fetch(`${adminMainUrl}/api/gender/get`, config)
            .then((res) => {

                res.json().then((resData) => {
                    dispatch(fetchGenderSuccess(resData));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchGenderRequest = () => ({
    type: "FIND_GENDER_TYPE_PAGE_REQUEST",
});

export const fetchGenderSuccess = (data) => ({
    type: "FIND_GENDER_TYPE_PAGE_SUCCESS",
    payload: data,
});

export const fetchGenderTypeFailure = (error) => ({
    type: "FIND_GENDER_TYPE_PAGE_FAILURE",
    payload: error,
});
//Gender//

//Designation//
export const DesignationApi = (slug) => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        };


        await fetch(`${adminMainUrl}/api/designation/get`, config)
            .then((res) => {

                res.json().then((resData) => {
                    dispatch(fetchDesignationSuccess(resData));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchDesignationRequest = () => ({
    type: "FIND_DESIGNATION_TYPE_PAGE_REQUEST",
});

export const fetchDesignationSuccess = (data) => ({
    type: "FIND_DESIGNATION_TYPE_PAGE_SUCCESS",
    payload: data,
});

export const fetchDesignationTypeFailure = (error) => ({
    type: "FIND_DESIGNATION_TYPE_PAGE_FAILURE",
    payload: error,
});
//Designation//

//WeightUnit//
export const WeightUnitApi = (slug) => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        };


        await fetch(`${adminMainUrl}/api/weight-unit/get`, config)
            .then((res) => {

                res.json().then((resData) => {
                    dispatch(fetchWeightUnitSuccess(resData));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchWeightUnitRequest = () => ({
    type: "FIND_WEIGHT_UNIT_PAGE_REQUEST",
});

export const fetchWeightUnitSuccess = (data) => ({
    type: "FIND_WEIGHT_UNIT_PAGE_SUCCESS",
    payload: data,
});

export const fetchWeightUnitTypeFailure = (error) => ({
    type: "FIND_WEIGHT_UNIT_PAGE_FAILURE",
    payload: error,
});
//WeightUnit//

//RequiredService//
export const RequiredServiceApi = (slug) => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        };


        await fetch(`${adminMainUrl}/api/required-service/get`, config)
            .then((res) => {

                res.json().then((resData) => {
                    dispatch(fetchRequiredServiceSuccess(resData));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};
export const fetchRequiredServiceRequest = () => ({
    type: "FIND_REQUIRED_SERVICE_PAGE_REQUEST",
});

export const fetchRequiredServiceSuccess = (data) => ({
    type: "FIND_REQUIRED_SERVICE_PAGE_SUCCESS",
    payload: data,
});

export const fetchRequiredServiceTypeFailure = (error) => ({
    type: "FIND_REQUIRED_SERVICE_PAGE_FAILURE",
    payload: error,
});
//RequiredService//


//view rates
export const fetchViewRateRequest = () => ({
    type: "FIND_VIEW_RATE_REQUEST",
});

export const fetchViewRateSuccess = (data) => ({
    type: "FIND_VIEW_RATE_SUCCESS",
    payload: data,
});

export const fetchViewRateFailure = (error) => ({
    type: "FIND_VIEW_RATE_FAILURE",
    payload: error,
});

export const getViewRate = (token, id) => {
    return async function (dispatch) {
        let config = {
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
            }
        };
        const requestBody = {
            "quotation_id": `${id}`
        };
        await axios.post(`${adminMainUrl}/api/pricing/list-export-info`, requestBody, config)
            .then((response) => {
                // console.log(response.data)
                dispatch(fetchViewRateSuccess(response.data?.data));  // Access data from the response
            })
            .catch((error) => {
                console.log("ERROR in getViewRate function (redux):", error);
            });
    }
};

//view rates

//download quotation
export const fetchQuotationRequest = () => ({
    type: "FIND_DOWNLOAD_QUOTATION_REQUEST",
});

export const fetchQuotationSuccess = (data) => ({
    type: "FIND_DOWNLOAD_QUOTATION_SUCCESS",
    payload: data,
});

export const fetchQuotationFailure = (error) => ({
    type: "FIND_DOWNLOAD_QUOTATION_FAILURE",
    payload: error,
});

export const getQuotation = (token, id) => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        };


        await fetch(`${adminMainUrl}/api/pricing/download-quote/${id}`, config)
            .then((res) => {

                res.json().then((resData) => {
                    dispatch(fetchQuotationSuccess(resData));
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err);
            })
    }
};



//download quotation