const initialState = {
    //gender
    loadingGenderApi: false,
    genderDataError: "",
    genderData: [],

    //idproof
    loadingIdproofApi: false,
    idproofDataError: "",
    idproofData: [],

    //weight unit
    loadingWeightApi: false,
    weightDataError: "",
    weightData: [],

    //country
    loadingCountryApi: false,
    countryDataError: "",
    countryData: [],

    //state
    loadingStateApi: false,
    stateDataError: "",
    stateData: [],

    //city
    loadingCityApi: false,
    cityDataError: "",
    cityData: [],

    //residential country
    loadingResidentialCountryApi: false,
    residentialCountryDataError: "",
    residentialCountryData: [],

    //residential state
    loadingResidentialStateApi: false,
    residentialStateDataError: "",
    residentialStateData: [],

    //residential city
    loadingResidentialCityApi: false,
    residentialCityDataError: "",
    residentialCityData: [],

    //department
    loadingDepartmentApi: false,
    departmentDataError: "",
    departmentData: [],

    //designation
    loadingDesignationApi: false,
    designationDataError: "",
    designationData: [],


    //employee
    loadingEmployeeApi: false,
    employeeDataError: "",
    employeeData: [],

    //role
    loadingRoleList: false,
    roleListDataError: "",
    roleListData: [],

    //view-users
    loadingViewUser: true,
    viewUserData: [],
    viewUserError: "",

}

const EmployeeMasterReducer = (state = initialState, action) => {
    switch (action.type) {
        //gender//
        case 'FIND_GENDER_REQUEST':
            return {
                ...state,
                loadingGenderApi: true,
                genderData: "",
                genderDataError: '',
            };
        case 'FIND_GENDER_SUCCESS':
            return {
                ...state,
                loadingGenderApi: false,
                genderData: action.payload,
            };
        case 'FIND_GENDER_FAILURE':
            return {
                ...state,
                loadingGenderApi: false,
                genderDataError: action.payload,
            };
        //gender//        

        //id proof//
        case 'FIND_IDPROOF_REQUEST':
            return {
                ...state,
                loadingIdproofApi: true,
                idproofData: "",
                idproofDataError: '',
            };
        case 'FIND_IDPROOF_SUCCESS':
            return {
                ...state,
                loadingIdproofApi: false,
                idproofData: action.payload,
            };
        case 'FIND_IDPROOF_FAILURE':
            return {
                ...state,
                loadingIdproofApi: false,
                idproofDataError: action.payload,
            };
        //id proof//

        //weight//
        case 'FIND_WEIGHT_REQUEST':
            return {
                ...state,
                loadingWeightApi: true,
                weightData: "",
                weightDataError: '',
            };
        case 'FIND_WEIGHT_SUCCESS':
            return {
                ...state,
                loadingWeightApi: false,
                weightData: action.payload,
            };
        case 'FIND_WEIGHT_FAILURE':
            return {
                ...state,
                loadingWeightApi: false,
                weightDataError: action.payload,
            };
        //weight//


        //country//
        case 'FIND_COUNTRY_REQUEST':
            return {
                ...state,
                loadingCountryApi: true,
                countryData: "",
                countryDataError: '',
            };
        case 'FIND_COUNTRY_SUCCESS':
            return {
                ...state,
                loadingCountryApi: false,
                countryData: action.payload,
            };
        case 'FIND_COUNTRY_FAILURE':
            return {
                ...state,
                loadingCountryApi: false,
                countryDataError: action.payload,
            };
        //country//

        //state//
        case 'FIND_STATE_REQUEST':
            return {
                ...state,
                loadingStateApi: true,
                stateData: "",
                stateDataError: '',
            };
        case 'FIND_STATE_SUCCESS':
            return {
                ...state,
                loadingStateApi: false,
                stateData: action.payload,
            };
        case 'FIND_STATE_FAILURE':
            return {
                ...state,
                loadingStateApi: false,
                stateDataError: action.payload,
            };
        //state//

        //city//
        case 'FIND_CITY_REQUEST':
            return {
                ...state,
                loadingCityApi: true,
                cityData: "",
                cityDataError: '',
            };
        case 'FIND_CITY_SUCCESS':
            return {
                ...state,
                loadingCityApi: false,
                cityData: action.payload,
            };
        case 'FIND_CITY_FAILURE':
            return {
                ...state,
                loadingCityApi: false,
                cityDataError: action.payload,
            };
        //city//

        //residential country//
        case 'FIND_R_COUNTRY_REQUEST':
            return {
                ...state,
                loadingResidentialCountryApi: true,
                residentialCountryData: "",
                residentialCountryDataError: '',
            };
        case 'FIND_R_COUNTRY_SUCCESS':
            return {
                ...state,
                loadingResidentialCountryApi: false,
                residentialCountryData: action.payload,
            };
        case 'FIND_R_COUNTRY_FAILURE':
            return {
                ...state,
                loadingResidentialCountryApi: false,
                residentialCountryDataError: action.payload,
            };
        //residential country//

        //residential state//
        case 'FIND_R_STATE_REQUEST':
            return {
                ...state,
                loadingResidentialStateApi: true,
                residentialStateData: "",
                residentialStateDataError: '',
            };
        case 'FIND_R_STATE_SUCCESS':
            return {
                ...state,
                loadingResidentialStateApi: false,
                residentialStateData: action.payload,
            };
        case 'FIND_R_STATE_FAILURE':
            return {
                ...state,
                loadingResidentialStateApi: false,
                residentialStateDataError: action.payload,
            };
        //residential state//

        //residential city//
        case 'FIND_R_CITY_REQUEST':
            return {
                ...state,
                loadingResidentialCityApi: true,
                residentialCityData: "",
                residentialCityDataError: '',
            };
        case 'FIND_R_CITY_SUCCESS':
            return {
                ...state,
                loadingResidentialCityApi: false,
                residentialCityData: action.payload,
            };
        case 'FIND_R_CITY_FAILURE':
            return {
                ...state,
                loadingResidentialCityApi: false,
                residentialCityDataError: action.payload,
            };
        //residential city//

        //department//
        case 'FIND_DEPARTMENT_REQUEST':
            return {
                ...state,
                loadingDepartmentApi: true,
                departmentData: "",
                departmentDataError: '',
            };
        case 'FIND_DEPARTMENT_SUCCESS':
            return {
                ...state,
                loadingDepartmentApi: false,
                departmentData: action.payload,
            };
        case 'FIND_DEPARTMENT_FAILURE':
            return {
                ...state,
                loadingDepartmentApi: false,
                departmentDataError: action.payload,
            };
        //department//

        //designation//
        case 'FIND_DESIGNATION_REQUEST':
            return {
                ...state,
                loadingDesignationApi: true,
                designationData: "",
                designationDataError: '',
            };
        case 'FIND_DESIGNATION_SUCCESS':
            return {
                ...state,
                loadingDesignationApi: false,
                designationData: action.payload,
            };
        case 'FIND_DESIGNATION_FAILURE':
            return {
                ...state,
                loadingDesignationApi: false,
                designationDataError: action.payload,
            };
        //designation//


        //employee//
        case 'FIND_EMPLOYEE_REQUEST':
            return {
                ...state,
                loadingEmployeeApi: true,
                employeeData: "",
                employeeDataError: '',
            };
        case 'FIND_EMPLOYEE_SUCCESS':
            return {
                ...state,
                loadingEmployeeApi: false,
                employeeData: action.payload,
            };
        case 'FIND_EMPLOYEE_FAILURE':
            return {
                ...state,
                loadingEmployeeApi: false,
                employeeDataError: action.payload,
            };
        //employee//

        //role List//
        case 'FIND_ROLE_LIST_REQUEST':
            return {
                ...state,
                loadingRoleList: true,
                roleListData: "",
                roleListDataError: '',
            };
        case 'FIND_ROLE_LIST_SUCCESS':
            return {
                ...state,
                loadingRoleList: false,
                roleListData: action.payload,
            };
        case 'FIND_ROLE_LIST_FAILURE':
            return {
                ...state,
                loadingRoleList: false,
                roleListDataError: action.payload,
            };
        //role List//

        //view user//
        case 'FIND_USER_LIST_REQUEST':
            return {
                ...state,
                loadingViewUser: true,
                viewUserData: "",
                viewUserDataError: '',
            };
        case 'FIND_USER_LIST_SUCCESS':
            return {
                ...state,
                loadingViewUser: false,
                viewUserData: action.payload,
            };
        case 'FIND_USER_LIST_FAILURE':
            return {
                ...state,
                loadingViewUser: false,
                viewUserDataError: action.payload,
            };
        //view user//
        default:
            return state;
    }
}

export default EmployeeMasterReducer;