import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
const Navbar = ({setShowSideBar,showSideBar}) => {
    return (
        <nav className="main-nav--bg">
            <div className="container main-nav">
            <div className='d-flex justify-content-start align-items-center gap-3'>
                    <button
                        className="sidebar-toggle transparent-btn"
                        title="Menu"
                        type="button"
                        onClick={()=>{setShowSideBar(!showSideBar)}}
                    >
                        <span className="sr-only">Toggle menu</span>
                        <span className="icon menu-toggle--gray" aria-hidden="true"></span>
                    </button>
                <div className="main-nav-start">
                    <h2 className="main-title">Client Onboarding</h2>
                </div>
                </div>
                <div className="main-nav-end">
                    {/* <div className="search-wrapper">
                       <SearchIcon style={{fill:"#04aed2"}}/>
                        <input type="text" placeholder="Search ..." required="" />
                    </div> */}
                    <a href="">
                        <img src="/img/will.png" alt="will" />
                    </a>

                
                    <div className="dropdown    ">
                        <button
                            className="nav-user-btn dropdown-btn dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                              <span className="nav-user-img">
                                <picture>
                                    <source srcSet="/img/Avatar.png" type="image/webp" />
                                    <img src="/img/Avatar.png" alt="User name" />
                                </picture>
                            </span>
                            <span className="sr-only-new">
                                David
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={18}
                                    height={18}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-chevron-down"
                                    aria-hidden="true"
                                >
                                    <polyline points="6 9 12 15 18 9" />
                                </svg>
                            </span>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <a className="dropdown-item" href="#">
                                <span>Contact Us</span>
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="#">
                                <span>Settings</span>
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="#">
                                <span>Log out</span>
                                </a>
                            </li>
                        </ul>
                    </div>


                   
                </div>
            </div>
        </nav>
    )
}

export default Navbar