import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { ChangePassword } from '../Modal/ChangePassword';
import Cookies from "js-cookie";
const adminMainUrl = process.env.REACT_APP_API_SERVER;


const Navbar = ({ setShowSideBar, showSideBar, userInfo, roleInfo }) => {
    const [show, setShow] = useState(false)
    // console.log("userInfo", userInfo);
    return (
        <>
            <nav className="main-nav--bg">
                <div className="container main-nav">
                    <div className='d-flex justify-content-start align-items-center gap-3'>

                        <button
                            className="sidebar-toggle transparent-btn"
                            title="Menu"
                            type="button"
                            onClick={() => { setShowSideBar(!showSideBar) }}
                        >
                            <span className="sr-only">Toggle menu</span>
                            <span className="icon menu-toggle--gray" aria-hidden="true"></span>
                        </button>
                        <div className="main-nav-start">
                            <h2 className="main-title mb-0">{roleInfo}</h2>
                        </div>
                    </div>
                    <div className="main-nav-end admin_profile_deat">
                        {/* <SearchIcon style={{ fill: "#04aed2", cursor: "pointer" }} className='d-block d-sm-none' onClick={() => { setShow(!show) }} />
                        <div className="search-wrapper d-none d-sm-block">
                            <SearchIcon style={{ fill: "#04aed2" }} />
                            <input type="text" placeholder="Search ..." required="" />
                        </div> */}
                        <a href="" className="will_icon_not"><img src="/img/notification.png" alt="will" /> <span></span></a>


                        <div className="dropdown">
                            <button
                                className="nav-user-btn dropdown-btn dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <span className="nav-user-img" title={userInfo && `${userInfo?.first_name} ${userInfo?.middle_name ?? ""} ${userInfo?.last_name}`}>
                                    <picture>
                                        {/* <source
                                            srcSet={`${adminMainUrl}/${userInfo.profile_image}`}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = "/img/no-profile.png";
                                            }}
                                            type="image/webp" /> */}
                                        <img
                                            src={`${adminMainUrl}/${userInfo.profile_image}`}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = "/img/no-profile.png";
                                            }}
                                            alt="Committed cargo user" />
                                    </picture>
                                </span>
                                <span className="sr-only-new">
                                    {userInfo && `${userInfo?.first_name}`}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={18}
                                        height={18}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-chevron-down"
                                        aria-hidden="true"
                                    >
                                        <polyline points="6 9 12 15 18 9" />
                                    </svg>
                                </span>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <a className="dropdown-item" href="#">
                                        <span>Contact Us</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        <span>Settings</span>
                                    </a>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/admin-login" onClick={() => { Cookies.remove("token"); }}>
                                        <span>Log out</span>
                                    </Link>
                                </li>
                                <li>
                                    <a className="dropdown-item cursor-pointer" data-bs-toggle="modal" data-bs-target="#changePassword">
                                        <span >Change Password</span>
                                    </a>
                                </li>
                            </ul>
                        </div>



                    </div>
                </div>
                {
                    show && <div className="search-wrapper m-3 pb-3">

                        <input type="text" placeholder="Search ..." required="" />
                    </div>
                }

            </nav>
            <ChangePassword id="changePassword" />
        </>
    )
}

export default Navbar