import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import { toast } from 'react-toastify';
import { adminMainUrl } from '../../../Config';
import Cookies from "js-cookie";
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Select from "react-select";
import Api from '../../../Api';
import Sent from './Sent';
import InProcess from './InProcess';
import RateRequest from './RateRequest';
const AllRateRequest = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(true)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [status_id, setStatus_id] = useState('');
  const [full_name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [shipmentType, setShipmentType] = useState('');
  const [shipmentVia, setShipmentVia] = useState('');
  const [pickedBY, setPickedBy] = useState('');
  const [rateRequestList, setRateRequestList] = useState([]);
  const [inProcessList, setInProcessList] = useState([]);
  const [sentList, setSentList] = useState([]);
  const fromDate = startDate ? formatDate(startDate) : null;
  const toDate = endDate ? formatDate(endDate) : null;
  const [pageSize, setPageSize] = useState(10);
  const [isResetting, setIsResetting] = useState(false);
  const [shipmentTypeData, setShipmentTypeData] = useState([]);
  const [shipmentViaData, setShipmentViaData] = useState([]);
  const [RequiredServiceId, setRequiredServiceId] = useState([]);
  const [requiredServiceData, setRequiredServiceData] = useState('')
  const totalPages = rateRequestList?.pagination?.totalPages || 1;

  const arr = new Array(5).fill(null);
  function capitalizeText(text) {
    return text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCount(pageNumber);
    }
  };
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;

    if (totalPages <= maxPageNumbersToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (count <= 3) {
        pageNumbers.push(1, 2, 3, 4, '...', totalPages);
      } else if (count > 3 && count < totalPages - 2) {
        pageNumbers.push(1, '...', count - 1, count, count + 1, '...', totalPages);
      } else {
        pageNumbers.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      }
    }
    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();

  const headers = [
    'REQUEST NO.',
    'Date',
    'NAME',
    'EMAIL',
    'PHONE NUMBER',
    'ORIGIN',
    'DESTINATION',
    'SHIPMENT TYPE',
    'SHIPMENT VIA',
    'REQUIRED SERVICE',
    'PICKED BY',
    'actions',
  ];
  const fetchQuotations = async () => {
    setLoading(true)
    try {
      const token = Cookies.get("token");
      const requestBody = {
        search_by: searchText,
        request_no: status_id,
        name: full_name,
        email: email,
        phone: mobileNumber,
        origin: origin,
        destination: destination,
        shipment_type_id: shipmentType,
        shipment_via_id: shipmentVia,
        // required_servie_id:,
        start_date: fromDate,
        end_date: toDate,

        // quotation_date:,
        // page: currentPage,
        // pageSize: 10,
      };
      const response = await fetch(`${adminMainUrl}/api/pricing/requested-list?page=${count}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        setLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setLoading(false);
      const data = await response.json();
      setRateRequestList(data);
      setPageSize(data?.data?.totalCount)


    } catch (error) {
      setLoading(false);
      alert(error)
    } finally {
      setLoading(false);
      // setLoading(false);
    }
  };



  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const handleStartDateChange = (date) => {
    if (date && date.getTime() === endDate?.getTime()) {
      Swal.fire('Error', 'Start date and end date cannot be the same', 'error');
      return;
    }
    setStartDate(date);

    // Reset endDate if it is before the new startDate
    if (endDate && date && endDate.getTime() < date.getTime()) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (date) => {
    if (date && date.getTime() === startDate?.getTime()) {
      Swal.fire('Error', 'Start date and end date cannot be the same', 'error');
      return;
    }
    if (date && startDate && date.getTime() < startDate.getTime()) {
      Swal.fire('Error', 'End date cannot be before the start date', 'error');
      return;
    }
    setEndDate(date);
  };
  useEffect(() => {
    // console.log("filterTab",filterTab)
    fetchInProcessList()
    fetchQuotations();
    SentQuoteList()
  }, [count]);


  // const handlePageChange = (newPage) => {
  //   setCurrentPage(newPage);
  // };

  // const filteredQuotations = selectedStatus
  //   ? rateRequestList?.data?.priceRequests?.filter(quotation => quotation?.status_name === selectedStatus)
  //   : rateRequestList?.data?.priceRequests;
  // const handleStatusClick = (statusName) => {
  //   setSelectedStatus(statusName);
  // };
  // const statusClassMap = {
  //   "Requested": "color_1",
  //   "In process": "color_2",
  //   "Sent": "color_3",
  //   "Quotation Sent": "color_5",
  //   "Client Review": "color_6",
  //   "Converted": "color_7",
  //   "Not Converted": "color_8",
  //   "Picked": "color_4",
  //   "Price Viewed": "color_1",
  // };
  // const GetUserInfo = async () => {
  //   try {
  //     const data = await Api(
  //       `${mainUrl}/api/v.1/statusList/${status_id}`,
  //       "GET"
  //     );
  //     if (data.status === 200) {
  //       const targetStatuses = ["Requested", "In process", "Sent"];
  //       const filteredData = data?.data?.filter(status => targetStatuses.includes(status.status_name));
  //       setStatusList(filteredData)
  //     } else {
  //       console.error("API call was not successful:", data.error);
  //     }
  //   } catch (error) {

  //     console.error("Error fetching data:", error);
  //   }
  // };

  const fetchInProcessList = async () => {
    setLoading(true)
    try {
      const token = Cookies.get("token");
      const requestBody = {
        search_by: searchText,
        request_no: status_id,
        name: full_name,
        email: email,
        phone: mobileNumber,
        origin: origin,
        destination: destination,
        shipment_type_id: shipmentType,
        shipment_via_id: shipmentVia,
        required_servie_id: requiredServiceData,
        // required_servie_id:,
        start_date: fromDate,
        end_date: toDate,
        // quotation_date:,
        // page: currentPage,
        // pageSize: 10,
      };
      const response = await fetch(`${adminMainUrl}/api/pricing/inprocess-list?page=${count}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        setLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setLoading(false);
      const data = await response.json();
      setInProcessList(data);
      setPageSize(data?.data?.totalCount)


    } catch (error) {
      setLoading(false);
      alert(error)
    } finally {
      setLoading(false);
      // setLoading(false);
    }
  };

  const SentQuoteList = async () => {
    setLoading(true)
    try {
      const token = Cookies.get("token");
      const requestBody = {
        search_by: searchText,
        request_no: status_id,
        name: full_name,
        email: email,
        phone: mobileNumber,
        origin: origin,
        destination: destination,
        shipment_type_id: shipmentType,
        shipment_via_id: shipmentVia,
        required_servie_id: requiredServiceData,
        // required_servie_id:,
        start_date: fromDate,
        end_date: toDate,
        // quotation_date:,
        // page: currentPage,
        // pageSize: 10,
      };
      const response = await fetch(`${adminMainUrl}/api/pricing/sent-list?page=${count}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        setLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setLoading(false);
      const data = await response.json();
      setSentList(data);
      setPageSize(data?.data?.totalCount)


    } catch (error) {
      setLoading(false);
      alert(error)
    } finally {
      setLoading(false);
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (isResetting) {
      fetchQuotations();
      fetchInProcessList();
      SentQuoteList();
      setIsResetting(false);  // Reset the flag after fetching quotations
    }
    fetchQuotations();
    // GetUserInfo();
  }, [isResetting]);

  
  const resetFields = () => {
    setSearchText('');
    setStatus_id('');
    setName('');
    setEmail('');
    setMobileNumber('');
    setOrigin('');
    setDestination('');
    setShipmentType("");
    setShipmentVia('');
    setPickedBy('');
    setRequiredServiceData('');
    // setRequiredServiceId([]);
    // setShipmentViaData([]);
    // setShipmentTypeData([]);
    setStartDate(null);
    setEndDate(null);
    setIsResetting(true);
  };

  const AcceptRequest = async (quotationId) => {
    try {
      const token = Cookies.get("token");
      const requesId = {
        quotation_id: `${quotationId}`,
      };
      const response = await fetch(`${adminMainUrl}/api/pricing/accept-job`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(requesId),
      });
      const data = await response.json();
      if (!response.ok) {
        toast.error(data?.msg);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      toast.success(data?.msg);
      navigate('/admin-dashboard/query-info-give-rates', { state: { quotationId: quotationId } });
    } catch (error) {
      toast.error(error);
      console.log(error);
    }
  };
  const GetShipmentVia = async () => {
    try {
      const data = await Api(`${adminMainUrl}/api/shipment-via/get`, "GET");
      const options = data?.data
        ?.filter((item) => item.status === true).map((item) => ({
          shipmentViaId: item.id,
          value: item.name,
          label: item.name,
        }));
      setShipmentViaData(options);
    } catch (error) {
      setShipmentViaData([]);

      console.error("Error fetching data:", error);
    }
  };
  const RequiredService = async () => {
    try {
      const data = await Api(`${adminMainUrl}/api/required-service/get`, "GET");
      const options = data?.data
        ?.filter((item) => item.status === true).map((item) => ({
          RequiredServiceId: item.id,
          value: item.name,
          label: item.name,
        }));
      setRequiredServiceId(options);
    } catch (error) {
      setRequiredServiceId([]);

      console.error("Error fetching data:", error);
    }
  };
  const GetShipmentType = async () => {
    try {
      const data = await Api(`${adminMainUrl}/api/shipment-type/get`, "GET");
      const options = data?.data
        ?.filter((item) => item.status === true).map((item) => ({
          shipmentTypeId: item.id,
          value: item.name,
          label: item.name,
        }));
      setShipmentTypeData(options);
    } catch (error) {
      setShipmentTypeData([]);

      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    GetShipmentType();
    GetShipmentVia();
    RequiredService()
  }, []);



  return (
    <>
      <div className="container">
        <div className="col-md-12">
          <div className="main_section_detail">
            <div className="row">
              <div className="col-md-6">
                <div className="main-nav-det">
                  <h2>All Rate Requests</h2>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="button-det-list">
                  <Link to="/admin-dashboard/quote-request-form">
                    <span>
                      <img src="/img/place.png" alt="" />
                    </span>{" "}
                    Manual Form
                  </Link>
                </div>
              </div> */}
            </div>
            {/* =============Search bar============= */}
            <div className="main_detail_filter">
              <form action="#">
                <div className="row align-items-center">
                  <div className="col-lg-9">
                    <div className="search_main_filter">
                      <div className="row main_row">
                        <div className="col-md-4">
                          <div className="input_box_filet filter_icon">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                            />
                            <span>
                              <img src="/img/search.png" alt="" />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-2 ">
                          <div className="dropdown dropdown-filter-section">
                            <a
                              className="dropdown-toggle input_box_id_button"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span>
                                <img src="/img/bi_filter.png" alt="" />
                              </span>
                              Filter
                            </a>
                            <div
                              className="dropdown-menu main_drop_filter"
                              aria-labelledby="dropdownMenuLink"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <table className="content-table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">REQUEST NO.</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Request No."
                                        value={status_id}
                                        onChange={(e) => setStatus_id(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  {/* <tr>
                                    <td>
                                      <label htmlFor="#">DATE </label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Date"
                                      />
                                    </td>
                                  </tr> */}
                                  <tr>
                                    <td>
                                      <label htmlFor="#">NAME</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Name"
                                        value={full_name}
                                        onChange={(e) => setName(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">EMAIL</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">PHONE NUMBER</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Number"
                                        value={mobileNumber}
                                        onChange={(e) => setMobileNumber(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">ORIGIN</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Origin"
                                        value={origin}
                                        onChange={(e) => setOrigin(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">DESTINATION</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Destination"
                                        value={destination}
                                        onChange={(e) => setDestination(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">SHIPMENT TYPE</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <Select
                                        options={shipmentTypeData}
                                        onChange={(selectedOption) => setShipmentType(selectedOption.shipmentTypeId)}
                                      />
                                      {/* {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Shipment Type"
                                        value={shipmentType}
                                        onChange={(e) => setShipmentType(e.target.value)}
                                      /> */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">SHIPMENT VIA</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <Select
                                        options={shipmentViaData}
                                        onChange={(selectedOption) => setShipmentVia(selectedOption.shipmentViaId)}
                                      />
                                      {/* {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Shipment Via"
                                        value={shipmentVia}
                                        onChange={(e) => setShipmentVia(e.target.value)}
                                      /> */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">REQUIRED SERVICE</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <Select
                                        options={RequiredServiceId}
                                        onChange={(selectedOption) => setRequiredServiceData(selectedOption.RequiredServiceId)}
                                      />
                                      {/* {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Required Service"
                                      /> */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">PICKED BY</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Picked By"
                                        value={pickedBY}
                                        onChange={(e) => setPickedBy(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="apply-btn cursor-pointer" onClick={() => {
                                fetchQuotations();
                                fetchInProcessList();
                                SentQuoteList();
                                const dropdownElement = document.getElementById('dropdownMenuLink');
                                const bootstrapDropdown = new window.bootstrap.Dropdown(dropdownElement);
                                bootstrapDropdown.hide();
                              }}>
                                Apply
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div className="input_box_section por_main_detail-date">
                            <DatePicker
                              dateFormat="dd/MM/yy"
                              placeholderText="From"
                              selected={startDate}
                              onChange={handleStartDateChange}
                              isClearable
                            />

                            <span>
                              <img src="/img/date_icon.png" alt="Calendar" />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div className="input_box_section por_main_detail-date">
                            <DatePicker
                              dateFormat="dd/MM/yy"
                              placeholderText="To"
                              selected={endDate}
                              onChange={handleEndDateChange}
                              isClearable
                            />
                            <span>
                              <img src="/img/date_icon.png" alt="Calendar" />
                            </span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 d-flex align-items-center">
                    <div className='search-btn text-white w-fit ' onClick={() => {
                      fetchQuotations();
                      fetchInProcessList();
                      SentQuoteList();
                    }} style={{ background: "#04aed2", marginTop: "7px", marginBottom: "15px" }}>Search</div>
                    <div
                      className='search-btn text-white w-fit'
                      style={{ background: "#04aed2", marginTop: "7px", marginBottom: "15px" }}
                      onClick={() => { resetFields() }}
                    >
                      Clear
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* =============Search bar============= */}
            <div className="container mt-4">
              <ul className="nav nav-pills nav-slider mb-3 border-2" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button onClick={() => { resetFields(); fetchQuotations() }} className="nav-link fw-semibold active position-relative" id="pills-rebid-tab" data-bs-toggle="pill" data-bs-target="#pills-rebid" type="button" role="tab" aria-controls="pills-rebid" aria-selected="true">Requested</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button onClick={() => { fetchInProcessList(); resetFields() }} className="nav-link fw-semibold position-relative" id="pills-process-process" data-bs-toggle="pill" data-bs-target="#pills-process" type="button" role="tab" aria-controls="pills-process" aria-selected="false">In Process</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button onClick={() => { SentQuoteList(); resetFields() }} className="nav-link fw-semibold position-relative" id="pills-send-process" data-bs-toggle="pill" data-bs-target="#send-process" type="button" role="tab" aria-controls="send-process" aria-selected="false">Sent</button>
                </li>
              </ul>
              <div className="tab-content rounded-3 text-danger" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-rebid" role="tabpanel" aria-labelledby="pills-rebid-tab">
                  <div className="scroll_listing_detail">

                    <RateRequest  setCount={setCount}   fetchQuotations={()=>{fetchQuotations()}} AcceptRequest={AcceptRequest} List={rateRequestList} arr={arr} loading={loading} headers={headers} handlePageChange={handlePageChange} count={count} pageNumbers={pageNumbers} />
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-process" role="tabpanel" aria-labelledby="pills-process-tab">
                  <div className="scroll_listing_detail">
                    <InProcess setCount={setCount} fetchQuotations={()=>{fetchInProcessList()}} List={inProcessList} arr={arr} loading={loading} headers={headers} handlePageChange={handlePageChange} count={count} pageNumbers={pageNumbers} />

                  </div>
                </div>

                <div className="tab-pane fade" id="send-process" role="tabpanel" aria-labelledby="pills-send-process">
                  <div className="scroll_listing_detail">
                    <Sent setCount={setCount} fetchQuotations={()=>{SentQuoteList()}} List={sentList} arr={arr} loading={loading} headers={headers} handlePageChange={handlePageChange} count={count} pageNumbers={pageNumbers} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default AllRateRequest