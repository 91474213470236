import { FocusError } from 'focus-formik-error';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import * as Yup from "yup";
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import { getCountry, getState, getWeight } from '../../../Redux/action/Admin/EmployeeMasterAction';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';


const adminMainUrl = process.env.REACT_APP_API_SERVER;

const QueryInfoAirExportFormRepricing = ({ quotationId, handleClose, quoteCompGenInfoData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = Cookies.get("token");
    let [countryOptions, setCountryOptions] = useState([]);
    let [stateOptions, setStateOptions] = useState([]);
    let [weightOptions, setWeightOptions] = useState([]);
    const [disabled, setDisabled] = useState(false);
    //generate unique quotation_no
    const generateQuotationNumber = () => {
        const timestamp = Date.now(); // Get the current timestamp
        return `${timestamp}${quotationId}`; // Combine them into a unique string
    };



    useEffect(() => {
        //get masters data

        dispatch(getCountry());
        dispatch(getState(quoteCompGenInfoData?.com_country_id))
        dispatch(getWeight());
    }, []);

    //master-> country
    let weightData = useSelector(state => state?.EmployeeMasterReducer ? state?.EmployeeMasterReducer?.weightData : []);
    useEffect(() => {
        if (weightData) {
            let weightArr = [];
            weightData.map(val => { if (val.status) { weightArr.push({ value: val.id, label: val.name }) } });
            setWeightOptions(weightArr);
        }
    }, [weightData]);

    //master-> country
    let countryData = useSelector(state => state?.EmployeeMasterReducer ? state?.EmployeeMasterReducer?.countryData : []);
    useEffect(() => {
        if (countryData) {
            let countryArr = [];
            countryData.map(val => { if (val.status) { countryArr.push({ value: val.id, label: val.name }) } });
            setCountryOptions(countryArr);
        }
    }, [countryData]);

    //master-> state
    let stateData = useSelector(state => state?.EmployeeMasterReducer ? state?.EmployeeMasterReducer?.stateData : []);
    useEffect(() => {
        if (stateData) {
            let stateArr = [];
            stateData.map(val => { stateArr.push({ value: val.id, label: val.name }) });
            setStateOptions(stateArr);
        }
    }, [stateData]);

    const handleGetState = (id) => {
        try {
            if (id) {
                dispatch(getState(id));
            }
        } catch (error) {
            console.log("Error in handleGetState function:", error.message);
        }
    };


    const formik = useFormik({
        initialValues: {
            quotation_id: quotationId,

            com_company_name: quoteCompGenInfoData ? quoteCompGenInfoData?.com_company_name : "",
            com_address: quoteCompGenInfoData ? quoteCompGenInfoData?.com_address : "",
            com_phone_no: quoteCompGenInfoData ? quoteCompGenInfoData?.com_phone_no : "",
            com_email: quoteCompGenInfoData ? quoteCompGenInfoData?.com_email : "",
            com_gstin: quoteCompGenInfoData ? quoteCompGenInfoData?.com_gstin : "",
            com_country_id: quoteCompGenInfoData ? quoteCompGenInfoData?.com_country_id : "",
            com_state_id: quoteCompGenInfoData ? quoteCompGenInfoData?.com_state_id : "",

            gen_customer_name: quoteCompGenInfoData ? quoteCompGenInfoData?.gen_customer_name : "",
            gen_address: quoteCompGenInfoData ? quoteCompGenInfoData?.gen_address : "",
            gen_billing_account: quoteCompGenInfoData ? quoteCompGenInfoData?.gen_billing_account : "",
            gen_attention_to: quoteCompGenInfoData ? quoteCompGenInfoData?.gen_attention_to : "",
            gen_airline: quoteCompGenInfoData ? quoteCompGenInfoData?.gen_airline : "",
            gen_place_of_receipt: quoteCompGenInfoData ? quoteCompGenInfoData?.gen_place_of_receipt : "",
            gen_commodity: quoteCompGenInfoData ? quoteCompGenInfoData?.gen_commodity : "",
            gen_airport_of_landing: quoteCompGenInfoData ? quoteCompGenInfoData?.gen_airport_of_landing : "",
            gen_cargo_weight: quoteCompGenInfoData ? Number(quoteCompGenInfoData?.gen_cargo_weight) : "",
            gen_airport_of_discharge: quoteCompGenInfoData ? quoteCompGenInfoData?.gen_airport_of_discharge : "",
            gen_incoterms: quoteCompGenInfoData ? quoteCompGenInfoData?.gen_incoterms : "",
            gen_tentative_transit_time: quoteCompGenInfoData ? quoteCompGenInfoData.gen_tentative_transit_time : null,
            gen_find_place_of_delivery: quoteCompGenInfoData ? quoteCompGenInfoData?.gen_find_place_of_delivery : "",
            gen_notes: quoteCompGenInfoData ? quoteCompGenInfoData?.gen_notes : "",
        },

        validationSchema: Yup.object().shape({

            com_company_name: Yup.string().required('Please enter company name '),
            com_address: Yup.string().required('Please enter address '),
            com_phone_no: Yup.string()
                .matches(/^\d{10}$/, "Phone Number must contain exactly 10 digits")
                .required("Please enter phone number"),
            com_email: Yup.string()
                .email("Please enter a valid email ID")
                .required("Please enter your email ID"),
            com_gstin: Yup.string().required('Please enter GSTIN '),
            com_country_id: Yup.string().required('Please select country '),
            com_state_id: Yup.string().required('Please select state '),
            gen_customer_name: Yup.string().required('Please enter company name '),
            gen_address: Yup.string().required('Please enter address '),
            gen_billing_account: Yup.string().required('Please enter billing account '),
            gen_attention_to: Yup.string().required('Please enter attention to '),
            gen_airline: Yup.string().required('Please enter airline '),
            gen_place_of_receipt: Yup.string().required('Please enter place of receipt '),
            gen_commodity: Yup.string().required('Please enter commodity '),
            gen_airport_of_landing: Yup.string().required('Please enter air port of loading '),
            gen_cargo_weight: Yup.string().required('Please select cargo weight '),
            gen_airport_of_discharge: Yup.string().required('Please enter airport of discharge '),
            gen_incoterms: Yup.string().required('Please enter incoterms '),
            gen_tentative_transit_time: Yup.string().required('Please enter tentative transit time '),
            gen_find_place_of_delivery: Yup.string().required('Please enter final place of delivery '),
            gen_notes: Yup.string().required('Please enter notes '),
        }),

        onSubmit: async (values, { resetForm }) => {
            const timeOnly = values.gen_tentative_transit_time
                ? new Date(values.gen_tentative_transit_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
                : null;

            const updatedValues = {
                ...values,
                gen_tentative_transit_time: timeOnly, // Only send the time

            };

            setDisabled(true);
            try {
                const config = { headers: { "auth-token": token } };
                axios.post(`${adminMainUrl}/api/quotation/to_be_sent_to_repricing_with_gen_info`, values, config)
                    .then(response => {
                        if (response.status == 200) {
                            toast.success(response.data.msg);
                            setDisabled(false);
                            handleClose();
                            navigate("/admin-dashboard/query-list", { state: { quotationId: 9 } });
                            resetForm();
                        } else {
                            toast.success(response.data.msg);
                            setDisabled(false);
                        }
                    }).catch(err => {
                        console.log(err);
                        setDisabled(false);
                        if (err.response) {
                            toast.error(err.response.data.msg);
                        } else {
                            toast.error("Internal server error!")
                        }
                    })

            } catch (error) {
                console.log(error.message);
                setDisabled(false);
                toast.error(error.message);
            }

        },
    });

    const convertToDate = (timeString) => {
        if (!timeString) return null;

        let date;

        if (typeof timeString === 'string') {
            // Handle case when timeString is in HH:MM:SS format
            const [hours, minutes] = timeString.split(':');
            date = new Date();
            date.setHours(parseInt(hours), parseInt(minutes), 0); // Ensure seconds are set to 0
        } else if (timeString instanceof Date) {
            // If timeString is a Date object, return it directly
            date = timeString;
        }

        return date;
    };

    useEffect(() => {
        if (!formik.values.ship_quotation_no) {
            const uniqueQuotationNo = generateQuotationNumber();
            // formik.setFieldValue('ship_quotation_no', uniqueQuotationNo);
        }
    }, [formik]);


    return (
        <>
            <form className="Air_Export_bg_main" onSubmit={formik.handleSubmit}>
                <FocusError formik={formik} />
                {/* <input type="hidden" name="uhqpa_id" value={status_id} /> */}
                {/* Company Information */}
                <div className="main-nav-det">
                    <h4>
                        <span>
                            <img src="/img/Product.png" alt="gen" />
                        </span>
                        Company Information
                    </h4>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Company Name <span>*</span> </label>
                            <input
                                type="text"
                                placeholder='Enter company name'
                                className="form-control"
                                value={formik.values.com_company_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="com_company_name"
                            />
                            {formik.touched.com_company_name && formik.errors.com_company_name ? (
                                <div className="error">{formik.errors.com_company_name}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Address <span>*</span> </label>
                            <input
                                type="text"
                                placeholder='Enter address'
                                className="form-control"
                                value={formik.values.com_address}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="com_address"
                            />
                            {formik.touched.com_address && formik.errors.com_address ? (
                                <div className="error">{formik.errors.com_address}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Phone Number <span>*</span> </label>
                            <input
                                placeholder='Enter phone number'
                                maxLength={10}
                                type="text"
                                className="form-control"
                                value={formik.values.com_phone_no}
                                onChange={(e) => {
                                    const num = e.target.value.replace(/[^0-9]/g, '');
                                    formik.setFieldValue('com_phone_no', num);
                                }}
                                onBlur={formik.handleBlur}
                                name="com_phone_no"
                            />
                            {formik.touched.com_phone_no && formik.errors.com_phone_no ? (
                                <div className="error">{formik.errors.com_phone_no}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Email ID <span>*</span> </label>
                            <input
                                placeholder='Enter email ID'
                                type="text"
                                className="form-control"
                                value={formik.values.com_email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="com_email"
                            />
                            {formik.touched.com_email && formik.errors.com_email ? (
                                <div className="error">{formik.errors.com_email}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>GSTIN <span>*</span> </label>
                            <input
                                type="text"
                                maxLength={15}
                                placeholder='Enter GSTIN'
                                className="form-control"
                                value={formik.values.com_gstin}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="com_gstin"
                            />
                            {formik.touched.com_gstin && formik.errors.com_gstin ? (
                                <div className="error">{formik.errors.com_gstin}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Country <span>*</span> </label>
                            <Select
                                className='mt-2'
                                options={countryOptions}
                                name="com_country_id"
                                value={countryOptions.find(option => option.value === formik.values.com_country_id)}
                                onChange={option => { formik.setFieldValue('com_country_id', option.value); handleGetState(option.value); formik.setFieldValue('state_id', ''); }}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.com_country_id && formik.errors.com_country_id ? (
                                <div className="error">{formik.errors.com_country_id}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>State <span>*</span> </label>


                            <Select
                                className='mt-2'
                                options={stateOptions}
                                name="com_state_id"
                                value={stateOptions.find(option => option.value === formik.values.com_state_id)}
                                onChange={option => { formik.setFieldValue('com_state_id', option.value); }}
                                onBlur={formik.handleBlur}
                            />

                            {formik.touched.com_state_id && formik.errors.com_state_id ? (
                                <div className="error">{formik.errors.com_state_id}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="main-nav-det">
                    <h4>
                        <span>
                            <img src="/img/Product.png" alt="gen" />
                        </span>
                        General Information
                    </h4>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>
                                Customer Name <span>*</span>
                            </label>
                            <input
                                placeholder='Enter customer name'
                                type="text"
                                className="form-control"
                                value={formik?.values?.gen_customer_name}
                                name="gen_customer_name"
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                            />
                            {formik?.touched?.gen_customer_name && formik?.errors?.gen_customer_name ? (
                                <div className="error">{formik?.errors?.gen_customer_name}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Address <span>*</span></label>
                            <input
                                placeholder='Enter address'
                                type="text"
                                className="form-control"
                                value={formik?.values?.gen_address}
                                name="gen_address"
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                            />
                            {formik?.touched?.gen_address && formik?.errors?.gen_address ? (
                                <div className="error">{formik?.errors?.gen_address}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Billing Account <span>*</span></label>
                            <input
                                type="text"
                                placeholder='Enter billing account'
                                className="form-control"
                                value={formik?.values?.gen_billing_account}
                                name="gen_billing_account"
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                            />
                            {formik?.touched?.gen_billing_account && formik?.errors?.gen_billing_account ? (
                                <div className="error">{formik?.errors?.gen_billing_account}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Attention To <span>*</span></label>
                            <input
                                type="text"
                                placeholder='Enter attention to'
                                className="form-control"
                                value={formik?.values?.gen_attention_to}
                                name="gen_attention_to"
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                            />
                            {formik?.touched?.gen_attention_to && formik?.errors?.gen_attention_to ? (
                                <div className="error">{formik?.errors?.gen_attention_to}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Airline <span>*</span></label>
                            <input
                                placeholder='Enter airline'
                                type="text"
                                className="form-control"
                                value={formik?.values?.gen_airline}
                                name="gen_airline"
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                            />
                            {formik?.touched?.gen_airline && formik?.errors?.gen_airline ? (
                                <div className="error">{formik?.errors?.gen_airline}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Place of Receipt <span>*</span></label>
                            <input
                                placeholder='Enter place of receipt'
                                type="text"
                                className="form-control"
                                value={formik?.values?.gen_place_of_receipt}
                                name="gen_place_of_receipt"
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                            />
                            {formik?.touched?.gen_place_of_receipt && formik?.errors?.gen_place_of_receipt ? (
                                <div className="error">{formik?.errors?.gen_place_of_receipt}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Commodity <span>*</span></label>
                            <input
                                placeholder='Enter commodity'
                                type="text"
                                className="form-control"
                                value={formik?.values?.gen_commodity}
                                name="gen_commodity"
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                            />
                            {formik?.touched?.gen_commodity && formik?.errors?.gen_commodity ? (
                                <div className="error">{formik?.errors?.gen_commodity}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>
                                Air Port of Loading <span>*</span>
                            </label>
                            <input
                                placeholder='Enter air port of loading '
                                type="text"
                                className="form-control"
                                value={formik?.values?.gen_airport_of_landing}
                                name="gen_airport_of_landing"
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                            />
                            {formik?.touched?.gen_airport_of_landing && formik?.errors?.gen_airport_of_landing ? (
                                <div className="error">{formik?.errors?.gen_airport_of_landing}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Cargo Weight <span>*</span></label>
                            {/* <Select
                                className='mt-2'
                                options={weight}
                                onChange={(selectedOption) =>
                                    formik.setFieldValue(
                                        "cou",
                                        selectedOption.weightId
                                    )
                                }
                                onBlur={formik.handleBlur}
                            /> */}

                            <Select
                                className='mt-2'
                                options={weightOptions}
                                name="gen_cargo_weight"
                                value={weightOptions.find(option => option.value === formik.values.gen_cargo_weight)}
                                onChange={option => { formik.setFieldValue('gen_cargo_weight', option.value); }}
                                onBlur={formik.handleBlur}
                            />

                            {formik?.touched?.gen_cargo_weight && formik?.errors?.gen_cargo_weight ? (
                                <div className="error">{formik?.errors?.gen_cargo_weight}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>
                                Air Port of Discharge <span>*</span>
                            </label>
                            <input
                                placeholder='Enter air port of discharge'
                                type="text"
                                className="form-control"
                                value={formik?.values?.gen_airport_of_discharge}
                                name="gen_airport_of_discharge"
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                            />
                            {formik?.touched?.gen_airport_of_discharge && formik?.errors?.gen_airport_of_discharge ? (
                                <div className="error">{formik?.errors?.gen_airport_of_discharge}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Incoterms <span>*</span></label>
                            <input
                                placeholder='Enter incoterms'
                                type="text"
                                className="form-control"
                                value={formik?.values?.gen_incoterms}
                                name="gen_incoterms"
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                            />
                            {formik?.touched?.gen_incoterms && formik?.errors?.gen_incoterms ? (
                                <div className="error">{formik?.errors?.gen_incoterms}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>
                                Tentative Transit Time <span>*</span>
                            </label>
                            <div className='custom-datepicker'>
                                <DatePicker
                                    placeholderText="Enter transit time"
                                    selected={formik.values.gen_tentative_transit_time ? convertToDate(formik.values.gen_tentative_transit_time) : null}
                                    onChange={(date) => formik.setFieldValue('gen_tentative_transit_time', date || null)}
                                    onBlur={formik.handleBlur}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    className="form-control"
                                    name="gen_tentative_transit_time"
                                />
                            </div>

                            {formik?.touched?.gen_tentative_transit_time && formik?.errors?.gen_tentative_transit_time ? (
                                <div className="error">{formik?.errors?.gen_tentative_transit_time}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>
                                Final Place of Delivery <span>*</span>
                            </label>
                            <input
                                placeholder='Enter final place of delivery'
                                type="text"
                                className="form-control"
                                value={formik?.values?.gen_find_place_of_delivery}
                                name="gen_find_place_of_delivery"
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                            />
                            {formik?.touched?.gen_find_place_of_delivery && formik?.errors?.gen_find_place_of_delivery ? (
                                <div className="error">{formik?.errors?.gen_find_place_of_delivery}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Notes </label>
                            <textarea
                                rows={3}
                                className="form-control"
                                placeholder='Enter notes'
                                value={formik?.values?.gen_notes}
                                name="gen_notes"
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                            />
                            {formik?.touched?.gen_notes && formik?.errors?.gen_notes ? (
                                <div className="error">{formik?.errors?.gen_notes}</div>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className="main-nav-det-button text-right main-Export-button">
                    <button className="btn search-btn" type="submit" disabled={disabled}>
                        {disabled ? <div><i className="fa fa-spinner fa-spin" /> Submit</div> : "Submit"}
                    </button>
                </div>
            </form>

        </>
    )
}

export default QueryInfoAirExportFormRepricing