import React, { useState, useEffect } from "react";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import CancelIcon from "@mui/icons-material/Cancel";
import { useFormik } from "formik";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import axios from 'axios';
import { FocusError } from 'focus-formik-error';
import {
    validationCreateServiceSchema,
} from "../../../ValidationScema";
import { admin, adminMainUrl } from "../../../Config";
import Api from "../../../Api";
import { useLocation, useNavigate } from "react-router-dom";
const EditQuoteRequestForm = ({ accessedModules, accessedPermission }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date());
    const [productType, setProductTypes] = useState([]);
    const [cargoType, setCargoType] = useState([]);
    const [shipmentMode, setShipmentMode] = useState([]);
    const [shipmentType, setShipmentType] = useState([]);
    const [shipmentVia, setShipmentVia] = useState([]);
    const [packageType, setPackageType] = useState([]);
    const [selectedImg, setSelectedImg] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState([]);
    const [weightUnit, setWeightUnit] = useState([]);
    const [RequiredServiceId, setRequiredServiceId] = useState([]);
    const [viewDetails, setViewDetails] = useState(null);
    const [quotationId, setQuotationId] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    useEffect(() => {
        if (location.state?.ViewDetails) {
            setViewDetails(location.state.ViewDetails);
        }
        if (location.state?.quotationId) {
            setQuotationId(location.state?.quotationId)
        }
    }, [location.state]);
    const isLastRowFilled = () => {
        const lastRow =
            formik.values.package_info[formik.values.package_info?.length - 1];
        return Object.values(lastRow).every((value) => value !== "");
    };
    const handleRemoveRow = (index) => {
        const updatedPackageInfo = formik.values.package_info.filter(
            (_, i) => i !== index
        );
        formik.setFieldValue("package_info", updatedPackageInfo);
    };

    const handleAddRow = () => {
        if (isLastRowFilled()) {
            formik.setFieldValue("package_info", [
                ...formik.values.package_info,
                {
                    package_type_id: "",
                    length: "",
                    width: "",
                    height: "",
                    max_weight: "",
                    max_weight_unit_id: "",
                    gross_weight: "",
                    gross_weight_unit_id: "",
                    total_boxes: ""
                },
            ]);
        } else {
            Swal.fire(
                "Error",
                "Please fill in all fields before adding a new row.",
                "error"
            );
        }
    };

    const GetProductType = async () => {
        try {
            const data = await Api(`${adminMainUrl}/api/product-type/get`, "GET");

            // Filter to include only items where status is true
            const options = data?.data
                ?.filter((item) => item.status === true)
                .map((item) => ({
                    productId: item.id,
                    value: item.name,
                    label: item.name,
                }));

            setProductTypes(options);
        } catch (error) {
            setProductTypes([]);
            console.error("Error fetching data:", error);
        }
    };

    const GetWeightUnit = async () => {
        try {
            const data = await Api(`${adminMainUrl}/api/weight-unit/get`, "GET");
            const options = data?.data
                ?.filter((item) => item.status === true).map((item) => ({
                    WeightUnitId: item.id,
                    value: item.name,
                    label: item.name,
                }));
            setWeightUnit(options);
        } catch (error) {
            setWeightUnit([]);

            console.error("Error fetching data:", error);
        }
    };
    const RequiredService = async () => {
        try {
            const data = await Api(`${adminMainUrl}/api/required-service/get`, "GET");
            const options = data?.data
                ?.filter((item) => item.status === true).map((item) => ({
                    RequiredServiceId: item.id,
                    value: item.name,
                    label: item.name,
                }));
            setRequiredServiceId(options);
        } catch (error) {
            setRequiredServiceId([]);

            console.error("Error fetching data:", error);
        }
    };
    const GetCargoType = async () => {
        try {
            const data = await Api(`${adminMainUrl}/api/cargo-type/get`, "GET");
            const options = data?.data
                ?.filter((item) => item.status === true).map((item) => ({
                    cargoId: item.id,
                    value: item.name,
                    label: item.name,
                }));
            setCargoType(options);
        } catch (error) {
            setCargoType([]);

            console.error("Error fetching data:", error);
        }
    };
    const GetShipmentMode = async () => {
        try {
            const data = await Api(`${adminMainUrl}/api/shipment-mode/get`, "GET");
            const options = data?.data
                ?.filter((item) => item.status === true).map((item) => ({
                    shipmentModeId: item.id,
                    value: item.name,
                    label: item.name,
                }));
            setShipmentMode(options);
        } catch (error) {
            setShipmentMode([]);

            console.error("Error fetching data:", error);
        }
    };
    const GetShipmentType = async () => {
        try {
            const data = await Api(`${adminMainUrl}/api/shipment-type/get`, "GET");
            const options = data?.data
                ?.filter((item) => item.status === true).map((item) => ({
                    shipmentTypeId: item.id,
                    value: item.name,
                    label: item.name,
                }));
            setShipmentType(options);
        } catch (error) {
            setShipmentType([]);

            console.error("Error fetching data:", error);
        }
    };
    const GetShipmentVia = async () => {
        try {
            const data = await Api(`${adminMainUrl}/api/shipment-via/get`, "GET");
            const options = data?.data
                ?.filter((item) => item.status === true).map((item) => ({
                    shipmentViaId: item.id,
                    value: item.name,
                    label: item.name,
                }));
            setShipmentVia(options);
        } catch (error) {
            setShipmentVia([]);

            console.error("Error fetching data:", error);
        }
    };
    const GetPackageType = async () => {
        try {
            const data = await Api(`${adminMainUrl}/api/package-type/get`, "GET");
            const options = data?.data
                ?.filter((item) => item.status === true).map((item) => ({
                    packageTypeId: item.id,
                    value: item.name,
                    label: item.name,
                }));
            setPackageType(options);
        } catch (error) {
            setPackageType([]);

            console.error("Error fetching data:", error);
        }
    };
    const unit = [{ value: "kg", label: "1" }];


    const cancelImage = (index) => {
        const newImages = formik.values.files.filter(
            (_, i) => i !== index
        );
        formik.setFieldValue("files", newImages);
        setSelectedImg(newImages);
    };

    const cancelVideo = (index) => {
        const newVideos = formik.values.videos.filter(
            (_, i) => i !== index
        );
        formik.setFieldValue("videos", newVideos);
        setSelectedVideo(newVideos);
    };
    const token = Cookies.get("token");
    const formik = useFormik({
        initialValues: {
            quotation_id: "",
            full_name: "",
            phone: "",
            email: "",
            origin: "",
            destination: "",
            product_name: "",
            product_type_id: "",
            shipment_type_id: "",
            shipment_mode_id: "",
            shipment_via_id: "",
            cargo_type_id: "",
            required_servie_id: "",
            expected_transit_date: "",
            detailed_requirement: "",
            agreement: 0,
            package_info: [{
                package_type_id: "",
                length: "",
                width: "",
                height: "",
                max_weight: "",
                max_weight_unit_id: "",
                gross_weight: "",
                gross_weight_unit_id: "",
                total_boxes: ""
            }],
            files: [],
            videos: ""
        },
        validationSchema: validationCreateServiceSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setIsSubmitting(true);
            let parsedValues = { ...values };

            try {
                // Parse package_info if it's in JSON string format
                parsedValues.package_info = JSON.parse(values.package_info);
            } catch (error) {
                // console.error("Error parsing package_info:", error);
            }

            // Ensure specific IDs are submitted as strings
            parsedValues.product_type_id = String(viewDetails?.product_type?.id || "");
            parsedValues.shipment_type_id = String(viewDetails?.shipment_type?.id || "");
            parsedValues.shipment_mode_id = String(viewDetails?.shipment_mode?.id || "");
            parsedValues.shipment_via_id = String(viewDetails?.shipment_via?.id || "");
            parsedValues.cargo_type_id = String(viewDetails?.cargo_type?.id || "");
            parsedValues.required_servie_id = String(viewDetails?.required_servie_id || "");

            try {
                // Apply trimming and ensure all package_info fields are strings
                if (Array.isArray(parsedValues.package_info)) {
                    parsedValues.package_info = parsedValues.package_info.map((pkg) => ({
                        ...pkg,
                        package_type_id: String(pkg.package_type_id || "").trim(),
                        length: String(pkg.length || "").trim(),
                        width: String(pkg.width || "").trim(),
                        height: String(pkg.height || "").trim(),
                        max_weight: String(pkg.max_weight || "").trim(),
                        max_weight_unit_id: String(pkg.max_weight_unit_id || "").trim(),
                        gross_weight: String(pkg.gross_weight || "").trim(),
                        gross_weight_unit_id: String(pkg.gross_weight_unit_id || "").trim(),
                        total_boxes: String(pkg.total_boxes || "").trim(),
                    }));
                }

                // Stringify package_info before submission
                parsedValues.package_info = JSON.stringify(parsedValues.package_info);

                // Send the POST request with the parsed and stringified data
                const response = await axios.post(`${adminMainUrl}/api/quotation/update`, parsedValues, {
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': token,
                    },
                });

                resetForm();
                toast.success(response.data.msg);
                navigate(-1);
            } catch (error) {
                console.error("API Error:", error);
            } finally {
                setSubmitting(false);
            }
        }
    });
    console.log(formik.values.package_info, "console.log(formik.values.package_info);");
    useEffect(() => {
        GetProductType();
        GetCargoType();
        GetShipmentMode();
        GetShipmentType();
        GetShipmentVia();
        GetPackageType();
        RequiredService();
        GetWeightUnit()
    }, []);
    useEffect(() => {
        if (viewDetails && quotationId) {
            formik.setValues({
                agreement: 0,
                quotation_id: `${quotationId}` || "",
                full_name: `${viewDetails?.full_name}` || "",
                phone: `${viewDetails?.phone}` || "",
                email: `${viewDetails?.email}` || "",
                origin: `${viewDetails?.origin}` || "",
                destination: `${viewDetails?.destination}` || "",
                product_name: `${viewDetails?.product_name}` || "",
                product_type_id: viewDetails?.product_type?.id || "",
                shipment_type_id: viewDetails?.shipment_type?.id || "",
                shipment_mode_id: viewDetails?.shipment_mode?.id || "",
                shipment_via_id: viewDetails?.shipment_via?.id || "",
                cargo_type_id: viewDetails?.cargo_type.id || "",
                required_servie_id: viewDetails?.required_servie_id || "",
                expected_transit_date: `${viewDetails?.expected_transit_date}` || "",
                detailed_requirement: `${viewDetails?.detailed_requirement}` || "",
                package_info: viewDetails?.package_info || [],
                files: `${viewDetails?.files}` || [],
                videos: `${viewDetails?.videos}` || ""
            });
        }
    }, [viewDetails, quotationId]);

    return (
        <>
            <div className="container">
                <div className="col-md-12">
                    <div className="main_section_detail">
                        <div className="main-nav-det">
                            <h2>Fill the Quote Request Form</h2>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <FocusError formik={formik} />
                            <div className="main_detail_form">
                                <div className="main-nav-det">
                                    <h4>General information</h4>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="input_box_section ">
                                            <label>
                                                Full Name <span>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="full_name"
                                                className="form-control"
                                                placeholder="Enter Name here"
                                                value={formik?.values?.full_name}
                                                // value={viewDetails?.full_name}
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                                pattern="[a-zA-Z\s]*"
                                                onKeyPress={(event) => {
                                                    if (!/[a-zA-Z\s]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                            {formik?.touched.full_name && formik?.errors.full_name ? (
                                                <div className="error">{formik?.errors.full_name}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input_box_section ">
                                            <label>
                                                Phone Number <span>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Phone Number"
                                                name="phone"
                                                // value={viewDetails?.phone}
                                                value={formik?.values?.phone}
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                                pattern="\d*"
                                                onKeyPress={(event) => {
                                                    if (!/\d/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                            {formik?.touched?.phone && formik?.errors?.phone ? (
                                                <div className="error">{formik?.errors?.phone}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input_box_section ">
                                            <label>
                                                Email Id <span>*</span>
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter Email"
                                                name="email"
                                                // value={viewDetails?.email}
                                                value={formik?.values.email}
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                            />
                                            {formik?.touched.email && formik?.errors?.email ? (
                                                <div className="error">{formik?.errors?.email}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input_box_section ">
                                            <label>
                                                Origin <span>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Origin "
                                                name="origin"
                                                // value={viewDetails?.origin}
                                                value={formik?.values?.origin}
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                            />
                                            {formik?.touched?.origin && formik?.errors?.origin ? (
                                                <div className="error">{formik?.errors?.origin}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input_box_section ">
                                            <label>
                                                Destination <span>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Destination"
                                                name="destination"
                                                // value={viewDetails?.destination}
                                                value={formik?.values?.destination}
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                            />
                                            {formik.touched.destination &&
                                                formik.errors.destination ? (
                                                <div className="error">{formik.errors.destination}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="main-nav-det">
                                    <h4>Pickup Information</h4>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="input_box_section">
                                            <label>
                                                Product Name <span>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="product_name"
                                                className="form-control"
                                                placeholder="Enter Product Name"
                                                // value={viewDetails?.product_name}
                                                value={formik?.values?.product_name}
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                            />
                                            {formik.touched.product_name &&
                                                formik.errors.product_name ? (
                                                <div className="error">
                                                    {formik.errors.product_name}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input_box_section">
                                            <label>Product Type</label>
                                            <div className="mt-2">
                                                <Select
                                                    options={productType}
                                                    value={productType.find(option => option.productId === formik.values.product_type_id)}
                                                    onChange={(selectedOption) =>
                                                        formik.setFieldValue(`product_type_id`, selectedOption.productId)
                                                    }
                                                    onBlur={formik.handleBlur}
                                                />

                                            </div>
                                            {formik.touched.product_type_id &&
                                                formik.errors.product_type_id ? (
                                                <div className="error">
                                                    {formik.errors.product_type_id}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input_box_section">
                                            <label>
                                                Shipment Type <span>*</span>
                                            </label>
                                            <div className="mt-2">
                                                <Select
                                                    options={shipmentType}
                                                    value={shipmentType.find(option => option.shipmentTypeId === formik.values.shipment_type_id)}
                                                    onChange={(selectedOption) =>
                                                        formik.setFieldValue(
                                                            "shipment_type_id",
                                                            selectedOption.shipmentTypeId
                                                        )
                                                    }
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>
                                            {formik.touched.shipment_type_id &&
                                                formik.errors.shipment_type_id ? (
                                                <div className="error">
                                                    {formik.errors.shipment_type_id}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input_box_section">
                                            <label>
                                                Shipment Mode <span>*</span>
                                            </label>
                                            <div className="mt-2">
                                                <Select
                                                    options={shipmentMode}
                                                    value={shipmentMode.find(option => option.shipmentModeId === formik.values.shipment_mode_id)}
                                                    onChange={(selectedOption) =>
                                                        formik.setFieldValue(
                                                            "shipment_mode_id",
                                                            selectedOption.shipmentModeId
                                                        )
                                                    }
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>
                                            {formik.touched.shipment_mode_id &&
                                                formik.errors.shipment_mode_id ? (
                                                <div className="error">
                                                    {formik.errors.shipment_mode_id}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input_box_section">
                                            <label>
                                                Shipment Via <span>*</span>
                                            </label>
                                            <div className="mt-2">
                                                <Select
                                                    options={shipmentVia}
                                                    value={shipmentVia.find(option => option.shipmentViaId === formik.values.shipment_via_id)}
                                                    onChange={(selectedOption) =>
                                                        formik.setFieldValue(
                                                            "shipment_via_id",
                                                            selectedOption.shipmentViaId
                                                        )
                                                    }
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>
                                            {formik.touched.shipment_via_id &&
                                                formik.errors.shipment_via_id ? (
                                                <div className="error">
                                                    {formik.errors.shipment_via_id}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input_box_section">
                                            <label>
                                                Cargo Type <span>*</span>
                                            </label>
                                            <div className="mt-2">
                                                <Select
                                                    options={cargoType}

                                                    value={cargoType.find(option => option.cargoId === formik.values.cargo_type_id)}
                                                    onChange={(selectedOption) =>
                                                        formik.setFieldValue(
                                                            "cargo_type_id",
                                                            selectedOption.cargoId
                                                        )
                                                    }
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>
                                            {formik.touched.cargo_type_id &&
                                                formik.errors.cargo_type_id ? (
                                                <div className="error">
                                                    {formik.errors.cargo_type_id}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>
                                                Required Service <span>*</span>
                                            </label>
                                            <div className="mt-2">
                                                <Select
                                                    options={RequiredServiceId}
                                                    value={RequiredServiceId.find(option => option.RequiredServiceId === formik.values.required_servie_id)}
                                                    onChange={(selectedOption) =>
                                                        formik.setFieldValue(
                                                            "required_servie_id",
                                                            selectedOption.RequiredServiceId
                                                        )
                                                    }
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.required_servie_id &&
                                                    formik.errors.required_servie_id ? (
                                                    <div className="error">
                                                        {formik.errors.required_servie_id}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>
                                                Expected Transit Date <span>*</span>
                                            </label>
                                            <div
                                                className="custom-datepicker"
                                                style={{ position: "relative" }}
                                            >
                                                <DatePicker
                                                    selected={formik.values.expected_transit_date ? new Date(formik.values.expected_transit_date) : startDate} // Display the existing date if available
                                                    minDate={new Date()}
                                                    onChange={(date) => {
                                                        if (date < new Date().setHours(0, 0, 0, 0)) {
                                                            Swal.fire(
                                                                "Error",
                                                                "Expected Date can not in past date",
                                                                "error"
                                                            );
                                                            setStartDate(null);
                                                            formik.setFieldValue("expected_transit_date", "");
                                                        } else {
                                                            setStartDate(date);
                                                            formik.setFieldValue(
                                                                "expected_transit_date",
                                                                date
                                                            );
                                                        }
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <img
                                                    src="/img/Calendar.png"
                                                    alt=""
                                                    style={{
                                                        position: "absolute",
                                                        top: "20px",
                                                        right: "12px",
                                                    }}
                                                />
                                            </div>
                                            {formik.touched.expected_transit_date &&
                                                formik.errors.expected_transit_date ? (
                                                <div className="error">
                                                    {formik.errors.expected_transit_date}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="input_box_section">
                                            <label>
                                                Detailed Requirement <span>*</span>
                                            </label>
                                            <textarea
                                                name="detailed_requirement"
                                                className="form-control"
                                                placeholder="Enter Detailed Requirement"
                                                // value={viewDetails?.detailed_requirement}
                                                value={formik?.values?.detailed_requirement}
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                                rows={5}
                                            />
                                            {formik.touched.detailed_requirement &&
                                                formik.errors.detailed_requirement ? (
                                                <div className="error">
                                                    {formik.errors.detailed_requirement}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="main-nav-det">
                                    <h4>Package Dimensions/Weight</h4>
                                </div>

                                <div>
                                    {formik.values.package_info?.map((packageItem, index) => (
                                        <div key={index} className="row">
                                            {/* Package Type Field */}
                                            <div className="col-md-4">
                                                <div className="input_box_section">
                                                    <label>Type of PKG <span>*</span></label>
                                                    <div className="mt-2">
                                                        <Select
                                                            options={packageType}
                                                            value={packageType.find(option => option.packageTypeId === packageItem.package_type?.id)}
                                                            onChange={(selectedOption) =>
                                                                formik.setFieldValue(`package_info.${index}.package_type.id`, selectedOption.packageTypeId)
                                                            }
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    {formik.touched.package_info?.[index]?.package_type_id &&
                                                        formik.errors.package_info?.[index]?.package_type_id ? (
                                                        <div className="error">{formik.errors.package_info[index].package_type_id}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            {/* Dimensions (L x W x H) Fields */}
                                            <div className="col-md-4">
                                                <div className="alfa_listing">
                                                    <div className="input_box_section">
                                                        <label>Dimensions (L x W x H per box) <span>*</span></label>
                                                        <ul className="mb-0 ps-0 mt-2">
                                                            <li>
                                                                <div className="input_box_section mb-0">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="L"
                                                                        value={packageItem.length}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        name={`package_info.${index}.length`}
                                                                        pattern="\d*"
                                                                        maxLength={4}
                                                                        onKeyPress={(event) => {
                                                                            if (!/\d/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="input_box_section mb-0">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="X"
                                                                        readOnly
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="input_box_section mb-0">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="W"
                                                                        value={packageItem.width}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        name={`package_info.${index}.width`}
                                                                        pattern="\d*"
                                                                        maxLength={4}
                                                                        onKeyPress={(event) => {
                                                                            if (!/\d/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="input_box_section mb-0">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="X"
                                                                        readOnly
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="input_box_section mb-0">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="H"
                                                                        value={packageItem.height}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        name={`package_info.${index}.height`}
                                                                        pattern="\d*"
                                                                        maxLength={4}
                                                                        onKeyPress={(event) => {
                                                                            if (!/\d/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        {formik.touched.package_info?.[index]?.length &&
                                                            formik.errors.package_info?.[index]?.length ? (
                                                            <div className="error">{formik.errors.package_info[index].length}</div>
                                                        ) : null}
                                                        {formik.touched.package_info?.[index]?.width &&
                                                            formik.errors.package_info?.[index]?.width &&
                                                            !formik.errors.package_info?.[index]?.length ? (
                                                            <div className="error">{formik.errors.package_info[index].width}</div>
                                                        ) : null}
                                                        {formik.touched.package_info?.[index]?.height &&
                                                            formik.errors.package_info?.[index]?.height &&
                                                            !formik.errors.package_info?.[index]?.length &&
                                                            !formik.errors.package_info?.[index]?.width ? (
                                                            <div className="error">{formik.errors.package_info[index].height}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Max Weight Field */}
                                            <div className="col-md-4">
                                                <div className="alfa_listing-kg">
                                                    <div className="input_box_section">
                                                        <label>Max Weight (per box) <span>*</span></label>
                                                        <ul className="mb-0 mt-2">
                                                            <li>
                                                                <div className="input_box_section mb-0">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Max weight"
                                                                        value={packageItem.max_weight}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        name={`package_info.${index}.max_weight`}
                                                                        pattern="\d*"
                                                                        maxLength={8}
                                                                        onKeyPress={(event) => {
                                                                            if (!/\d/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </li>
                                                            <li className="w-100">
                                                                <div className="input_box_section w-swction_main mb-0">
                                                                    <Select
                                                                        options={weightUnit}
                                                                        value={weightUnit.find(option => option.WeightUnitId === packageItem.max_weight_unit?.id)}
                                                                        onChange={(selectedOption) =>
                                                                            formik.setFieldValue(`package_info.${index}.max_weight_unit.id`, selectedOption.WeightUnitId)
                                                                        }
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        {formik.touched.package_info?.[index]?.max_weight &&
                                                            formik.errors.package_info?.[index]?.max_weight ? (
                                                            <div className="error">{formik.errors.package_info[index].max_weight}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Gross Weight Field */}
                                            <div className="col-md-4">
                                                <div className="alfa_listing-kg">
                                                    <div className="input_box_section">
                                                        <label>Total Gross Weight <span>*</span></label>
                                                        <ul className="mt-2 mb-0">
                                                            <li className="padd_left_inherit">
                                                                <div className="input_box_section mb-0">
                                                                    <input
                                                                        pattern="\d*"
                                                                        maxLength={8}
                                                                        onKeyPress={(event) => {
                                                                            if (!/\d/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Total Gross Weight"
                                                                        value={packageItem.gross_weight}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        name={`package_info.${index}.gross_weight`}
                                                                    />
                                                                </div>
                                                            </li>
                                                            <li className="w-100">
                                                                <div className="input_box_section w-swction_main mb-0">
                                                                    <Select
                                                                        options={weightUnit}
                                                                        value={weightUnit.find(option => option.WeightUnitId === packageItem.gross_weight_unit?.id)}
                                                                        onChange={(selectedOption) =>
                                                                            formik.setFieldValue(`package_info.${index}.gross_weight_unit.id`, selectedOption.WeightUnitId)
                                                                        }
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        {formik.touched.package_info?.[index]?.gross_weight &&
                                                            formik.errors.package_info?.[index]?.gross_weight ? (
                                                            <div className="error">{formik.errors.package_info[index].gross_weight}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Total Boxes Field */}
                                            <div className="col-md-4">
                                                <div className="input_box_section">
                                                    <label>Total no. of boxes <span>*</span></label>
                                                    <input
                                                        pattern="\d*"
                                                        maxLength={8}
                                                        onKeyPress={(event) => {
                                                            if (!/\d/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Total number of boxes"
                                                        value={packageItem.total_boxes}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        name={`package_info.${index}.total_boxes`}
                                                    />
                                                    {formik.touched.package_info?.[index]?.total_boxes &&
                                                        formik.errors.package_info?.[index]?.total_boxes ? (
                                                        <div className="error">{formik.errors.package_info[index].total_boxes}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            {/* Remove Button */}
                                            {formik.values.package_info.length > 1 && (
                                                <div>
                                                    <button
                                                        className="remoove btn btn-danger w-fit my-3"
                                                        onClick={() => handleRemoveRow(index)}
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    ))}

                                    <div className="col-md-6">
                                        <div className="main-nav-det ">
                                            <div className="d-md-flex justify-content-between align-items-center">
                                                <button
                                                    type="button"
                                                    className="bg-none "
                                                    onClick={handleAddRow}
                                                >
                                                    <span className="me-2">
                                                        <img src="/img/pla.png" alt="img" />
                                                    </span>
                                                    Add another package
                                                </button>
                                            </div>

                                            <div className="main_package_formar">
                                                <input
                                                    disabled
                                                    type="text"
                                                    className="form-control"
                                                    readOnly=""
                                                    placeholder="Download Package List Format"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-12">
                                    <div className="main_section_detail">
                                        <div className="related_to_main">
                                            <h5>Related documents or Videos</h5>
                                        </div>
                                        <div className="related_to_main">
                                            <h5>
                                                Upload relevant documents and specifications to help the
                                                forwarder
                                            </h5>
                                        </div>
                                        <ul className="main_list_numvar">
                                            <li>
                                                <p>
                                                    Uploading cargo-related documents, such as the
                                                    proforma invoice and packing list, will help
                                                    forwarders return the most accurate quotes.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    Accepted document formats: .jpg, .jpeg, .png, .pdf,
                                                    .docx, .doc, .xlsx, .xls. Accepted video formats:
                                                    .avi, .mp4, .wav.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    The maximum file size accepted is 10MB per file, with
                                                    a limit of 3 files. For videos, the maximum size
                                                    accepted is 100MB, with a limit of 1 video.
                                                </p>
                                            </li>
                                        </ul>
                                        <div className="main_select_photo">
                                            <ul>
                                                <li>
                                                    <div className="file-input image-input-box">
                                                        <input
                                                            type="file"
                                                            name="files"
                                                            accept="image/*"
                                                            multiple
                                                            id="files"
                                                            onChange={(event) => {
                                                                const selectedFiles = Array.from(event.target.files);
                                                                const combinedFiles = [...formik.values.files, ...selectedFiles];
                                                                if (combinedFiles.length > 3) {
                                                                    const limitedFiles = combinedFiles.slice(0, 3);
                                                                    formik.setFieldValue("files", limitedFiles);
                                                                    setSelectedImg(limitedFiles);
                                                                    Swal.fire(
                                                                        "Error",
                                                                        "You can only upload up to 3 images.",
                                                                        "error"
                                                                    );
                                                                } else {
                                                                    formik.setFieldValue("files", combinedFiles);
                                                                    setSelectedImg(combinedFiles);
                                                                }
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="folder_main_bro label_1"
                                                        >
                                                            <span>
                                                                <img src="/img/upload.png" alt="file" />
                                                            </span>{" "}
                                                            Upload File
                                                        </button>
                                                        {formik.touched.files &&
                                                            formik.errors.files ? (
                                                            <div>{formik.errors.files}</div>
                                                        ) : null}
                                                    </div>
                                                    {formik.touched.files &&
                                                        formik.errors.files ? (
                                                        <div className="error">
                                                            {formik.errors.files}
                                                        </div>
                                                    ) : null}
                                                    {selectedImg &&
                                                        selectedImg.map((img, index) => (
                                                            <div
                                                                key={index}
                                                                className="mt-3 border border-[#cac8c8] rounded-md w-[110px] h-[110px] flex justify-center items-center relative"
                                                            >
                                                                <CancelIcon
                                                                    onClick={() => cancelImage(index)}
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "12px",
                                                                        right: "6px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                />
                                                                <p className="my-3 pe-5 ps-2">{img.name}</p>

                                                            </div>
                                                        ))}
                                                </li>
                                                <li>
                                                    <div className="file-input video-input-box">
                                                        <input
                                                            type="file"
                                                            accept="video/*"
                                                            name="videos"
                                                            id="videos"
                                                            multiple
                                                            
                                                            onChange={(event) => {
                                                                const files = Array.from(event.target.files);
                                                                if (files.length) {
                                                                    const newVideos = [
                                                                        ...formik.values.videos,
                                                                        ...files,
                                                                    ];
                                                                    formik.setFieldValue(
                                                                        "videos",
                                                                        newVideos
                                                                    );
                                                                    setSelectedVideo(newVideos);
                                                                } else {
                                                                    console.log("No files selected");
                                                                }
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="folder_main_bro label_1"
                                                        >
                                                            <span>
                                                                <img src="/img/video.png" alt="video" />
                                                            </span>{" "}
                                                            Upload Videos
                                                        </button>
                                                        {formik.touched.videos &&
                                                            formik.errors.videos ? (
                                                            <div>{formik.errors.videos}</div>
                                                        ) : null}
                                                    </div>
                                                    {formik.touched.videos &&
                                                        formik.errors.videos ? (
                                                        <div className="error">
                                                            {formik.errors.videos}
                                                        </div>
                                                    ) : null}
                                                    {selectedVideo &&
                                                        selectedVideo.map((video, index) => (
                                                            <div
                                                                key={index}
                                                                className="border mt-3 border-[#cac8c8] rounded-md w-[110px] h-[110px] flex justify-center items-center relative"
                                                            >
                                                                <CancelIcon
                                                                    onClick={() => cancelVideo(index)}
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "12px",
                                                                        right: "6px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                />
                                                                <p className="my-3 pe-5 ps-2">{video.name}</p>
                                                             
                                                            </div>
                                                        ))}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="main-box-det-check mt-3">
                                            <label className="users-table__checkbox" id="new_text">
                                                <input
                                                    type="checkbox"
                                                    className="check"
                                                    name="agreement"
                                                    checked={formik.values.agreement === 1}
                                                    onChange={(event) => {
                                                        formik.setFieldValue(
                                                            "agreement",
                                                            event.target.checked ? 1 : 0
                                                        );
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                />
                                                I have read, understood and agreed to abide by the{" "}
                                                <b className="ms-2">Buying Request Posting Rules</b>
                                            </label>
                                            {formik.touched.agreement && formik.errors.agreement ? (
                                                <div className="error">{formik.errors.agreement}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div> */}
                                <div className="main-box-det-check mt-3">
                                    <label className="users-table__checkbox" id="new_text">
                                        <input
                                            type="checkbox"
                                            className="check"
                                            name="agreement"
                                            checked={formik.values.agreement === 1}
                                            onChange={(event) => {
                                                formik.setFieldValue("agreement", event.target.checked ? 1 : 0);
                                            }}
                                            onBlur={formik.handleBlur}
                                        />
                                        I have read, understood and agreed to abide by the{" "}
                                        <b className="ms-2">Buying Request Posting Rules .</b>
                                    </label>
                                    {formik.touched.agreement && formik.errors.agreement ? (
                                        <div className="error">{formik.errors.agreement}</div>
                                    ) : null}
                                </div>
                                <div className="row mt-4 mb-3">
                                    <div className="col-md-12">
                                        <button className="btn search-btn" type="submit" disabled={isSubmitting}>
                                            {isSubmitting ? <div><i className="fa fa-spinner fa-spin" /> Update</div> : "Update"}
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditQuoteRequestForm;
