import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
export const RemarkModal = ({ id, viewData }) => {
    return (
        <div
            className="modal fade"
            id={id}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content p-0 w-100">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Remarks
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                    </div>
                    <div className="modal-body">
                        {
                            viewData?.quote_remarks?.length > 0 ?
                                <>
                                    <Accordion defaultActiveKey="0">
                                        {viewData?.quote_remarks?.map((value, index) => (
                                            <Accordion.Item eventKey={index.toString()} key={index}>
                                                <Accordion.Header className='remark-accordion'>
                                                    {/* {new Date(value?.created_at).toISOString().split('T')[0]}{" "} */}
                                                    <div className="remark-header-content">
                                                        <span className="remark-user">
                                                            {value?.remark_user?.first_name} {value?.remark_user?.middle_name} {value?.remark_user?.last_name}
                                                        </span>
                                                        <div className='d-flex justify-content-start align-items-center gap-3'>
                                                        <span className="remark-date">2024-09-17</span>
                                                        <span className="remark-date">12:00</span>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    {value?.remarks}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                    {/* <div className='users-table-admin border-redi table-responsive border'>

                                        <table className="posts-table table-responsive ">
                                            <thead>
                                                <tr className='users-table-admin-th'>
                                                    <th>S no</th>
                                                    <th>Name</th>
                                                    <th>Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {viewData?.quote_remarks?.map((value, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            {value?.remark_user?.first_name} {value?.remark_user?.middle_name} {value?.remark_user?.last_name}
                                                        </td>
                                                        <td>{value?.remarks}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div> */}

                                </>
                                :
                                <p>No data available</p>
                        }
                    </div>

                </div>
            </div>
        </div>

    )
}
