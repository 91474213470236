import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
const Navbar = ({ setShowSideBar, showSideBar, userdata }) => {
    const [show, setShow] = useState(false)
    return (
        <>
            <nav className="main-nav--bg">
                <div className="container main-nav">
                    <div className='d-flex justify-content-start align-items-center gap-3'>

                        <button
                            className="sidebar-toggle transparent-btn"
                            title="Menu"
                            type="button"
                            onClick={() => { setShowSideBar(!showSideBar) }}
                        >
                            <span className="sr-only">Toggle menu</span>
                            <span className="icon menu-toggle--gray" aria-hidden="true"></span>
                        </button>
                        <div className="main-nav-start">
                            <h2 className="main-title mb-0">Pricing</h2>
                        </div>
                    </div>
                    <div className="main-nav-end">
                        {/* <SearchIcon style={{ fill: "#04aed2", cursor: "pointer" }} className='d-block d-sm-none' onClick={() => { setShow(!show) }} />
                        <div className="search-wrapper d-none d-sm-block">
                            <SearchIcon style={{ fill: "#04aed2" }} />
                            <input type="text" placeholder="Search ..." required="" />
                        </div> */}
                        <a href="">
                            <img src="/img/will.png" alt="will" />
                        </a>


                        <div className="dropdown    ">
                            <button
                                className="nav-user-btn dropdown-btn dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <span className="nav-user-img">
                                    <picture>
                                        <source srcSet="/img/Avatar.png" type="image/webp" />
                                        <img src="/img/Avatar.png" alt="User name" />
                                    </picture>
                                </span>
                                <span className="sr-only-new">
                                    John
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={18}
                                        height={18}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-chevron-down"
                                        aria-hidden="true"
                                    >
                                        <polyline points="6 9 12 15 18 9" />
                                    </svg>
                                </span>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <a className="dropdown-item" href="#">
                                        <span>Contact Us</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        <span>Settings</span>
                                    </a>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/">
                                        <span>Log out</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>



                    </div>
                </div>
                {
                show && <div className="search-wrapper m-3 pb-3">
                    
                    <input type="text" placeholder="Search ..." required="" />
                </div>
            }

            </nav>
        
        </>
    )
}

export default Navbar